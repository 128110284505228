.messages {
    &--form {
        &__block {
            margin-top: 16px;
            width: 600px;
        }
    }

    &table {
        &--newmessagelink {
            font-size: 10px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 1px;
            text-align: center;
            color: $wu-bottlegreen;
            border: 1px solid $wu-bottlegreen;
            text-transform: uppercase;
            border-radius: 35px;
            padding: 5px 15px;
        }

        &--emptycell {
            width: 146px;
            display: block;
            color: white;
        }
    }

    &--chat {
        &title {
            display: flex;
        }

        &subtitle {
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 700px;
        }

        &container {
            width: 730px;
            padding: 20px 0;
            height: calc(100% - 185px);
            position: relative;
            overflow: auto;
        }

        &__columnscontainer {
            height: calc(100% - 40px);
        }

        &date {
            font-family: Raleway;
            font-size: 11px;
            font-style: italic;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.1em;
            text-align: right;
            display: block;
        }

        &content {
            border-radius: 10px;
            padding: 20px;
            width: fit-content;
            max-width: 80%
        }

        &__line {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            white-space: pre-wrap;
        }

        &__lineclient {
            align-items: flex-end;
        }

        &__client {
            background-color: $wu-pastelgreen;
        }

        &__wattvalue {
            background-color: $wu-mediumdarkgrey;
        }

        &__form {
            position: fixed;
            bottom: 30px;
            width: 740px;
            left: 310px;
            background-color: $wu-lightgrey;
        }
    }
}

@media screen and (max-width: 1440px) {
    .messages {
        &--chat {
            &subtitle {
                width: 300px;
            }

            &__columnscontainer {
                width: 730px
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .messages {
        &--chat {
            &container {
                width: 600px;
                height: calc(100% - 250px);
            }

            &__columnscontainer {
                width: 600px
            }

            &__form {
                width: 600px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .messages {
        &--chat {
            &__form {
                left: 40px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .messages {
        &--chat {
            &container {
                width: 400px;
            }

            &__columnscontainer {
                width: 400px;
            }

            &__form {
                width: 400px;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .messages {
        &--form {
            &__block {
                width: 400px;
            }
        }
    }
}

@media screen and (max-width: 460px) {
    .messages {
        &--form {
            &__block {
                width: 250px;
            }
        }

        &--chat {
            &container {
                width: 250px;
            }

            &__columnscontainer {
                width: 250px;
            }

            &__form {
                width: 250px;
            }
        }
    }
}

@media screen and (max-height: 500px) {
    .messages {

        &--chat {
            &__form {
                position: initial;
            }

            &container {
                height: 100%;
                overflow: initial;
            }
        }
    }
}