.pageloader--turbine {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);

    height: 100%;
    width: 100%;
}

.pageloader--pole,
.pageloader--pilot {
    grid-row: 1;
    grid-column: 1;
}

.pageloader--pole {
    position: relative;
    display: block;
    align-self: end;
    background-color: dimgrey;
    height: 45%;
    width: 10px;
    border-radius: 5px 5px 0 0;
    z-index: 0;
}

.pageloader--pilot {
    position: relative;
    z-index: 1;
}


.pageloader--pilot:after {
    content: '';
    display: block;

    position: absolute;
    top: 40px;
    left: -6px;
    z-index: 1;

    height: 27px;
    width: 27px;
    border: 4px solid $wu-lightgrey;
    border-radius: 50%;

    box-sizing: border-box;
}

.pageloader--pilot:after,
.pageloader--pilot .pageloader--prop {
    background-color: $wu-bottlegreen;
}


.pageloader--pilot .pageloader--prop-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-items: center;
    animation: propeller 1.5s infinite linear;
    transform-origin: 7px 53px;
}

.pageloader--pilot .pageloader--prop {
    height: 50px;
    width: 14px;
    border-radius: 50%;
    grid-column: 1;
    grid-row: 1;
    transform-origin: 50% 50%;
}

.pageloader--prop:first-child {
    transform: rotate(360deg) translate(0px) rotate(-360deg);
}

.pageloader--prop:nth-child(2) {
    transform: rotateZ(120deg) rotate(120deg) translate(-50px) rotate(-120deg);
}

.pageloader--prop:last-child {
    transform: rotateZ(240deg) rotate(240deg) translate(50px) rotate(-240deg);
}


@keyframes propeller {
    to {
        transform: rotateZ(360deg);
    }
}