.subscription {
    &--container {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        &__block {
            width: 48%;
        }

        &__emptystate {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-left: 15px;
        }
    }

    &--left {
        &__title {
            display: block;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-bottom: 15px;
            line-height: 20px;
        }

        &__value {
            font-weight: 400;
        }

        &__prm {
            margin-bottom: 0;
            margin-top: 28px;
        }

        &__bold {
            font-weight: 700;
        }

        &__paragraph {
            margin-bottom: 15px;
            line-height: 20px;
        }

        &__list {
            line-height: 20px;
        }
    }

    &--right {
        border: 1px solid $wu-mediumdarkgrey;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        height: fit-content;

        &__price {
            font-family: Plus Jakarta Sans;
            font-size: 38px;
            font-weight: 700;

            &text {
                font-weight: 200;
            }
        }

        &__subtitle {
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            display: block;
            margin: 15px 0;
        }

        &__check {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            margin-top: 5px;
        }

        &__button {
            margin-top: 20px;
        }
    }

    &--link {
        color: $wu-bottlegreen;
        text-decoration: underline;
        cursor: pointer;
    }

    &--video {
        &__container {
            overflow: hidden;
            margin-top: 15px;

            & iframe {
                height: 315px;
                width: 560px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .subscription {
        &--container {
            flex-direction: column;

            &__block {
                width: 100%;
            }
        }

        &--right {
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 645px) {
    .subscription {
        &--video {
            &__container {
                & iframe {
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
}