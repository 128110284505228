.searchbar {
    &--container {
        position: relative;
    }

    &--magnifiericon {
        position: absolute;
        top: 8px;
        left: 10px;
    }

    &--bar {
        background-color: $wu-lightgrey;
        width: 100%;
        height: 28px;
        border-radius: 10px;
        border: 1px solid $wu-lightergrey;
        padding-left: 35px;

        &::placeholder {
            color: $wu-mediumgrey;
        }

        &:focus {
            outline: 1px solid black;
        }
    }

    &--siteslist {
        width: 55%;
    }

    &--form {
        width: 30%;
    }
}