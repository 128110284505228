.tooltip {
    &--container {
        position: absolute;
        align-items: center;
        z-index: 101;
        min-height: 39px;

        &__right {
            right: -170px
        }

        &__left {
            left: -170px
        }

        &__text {
            display: inline-block;
            height: fit-content;
            background-color: $wu-bottlegreen;
            font-family: Plus Jakarta Sans;
            font-size: 11px;
            font-weight: 500;
            line-height: 13px;
            text-align: center;
            color: white;
            width: 170px;
            padding: 5px;
            border-radius: 5px;
            text-wrap: auto;
        }

        &__white {
            background-color: white;
            color: #71717A;
            border: 1px solid #E4E4E7;
            padding: 10px 5px;
        }

        &__triangle {
            display: inline-block;
            height: 0;
            width: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;

            &right {
                border-right: 10px solid $wu-bottlegreen;
            }

            &left {
                border-left: 10px solid $wu-bottlegreen;
            }
        }
    }
}

@media screen and (max-width: 740px) {
    .tooltip {
        &--container {
            &__form {
                left: -18px !important;
                margin-top: 60px !important;
                flex-direction: column;
            }
            
            &__right {
                right: 0;

            }

            &__left {
                left: 0;
                right: 0;

            }

            &__triangle {

                &right {
                    display: none;
                }

                &left {
                    display: none;
                }

                &top {
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $wu-bottlegreen;
                    border-left: 10px solid transparent;
                    display: block;
                }
            }
        }
    }
}