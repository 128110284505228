.topbar {
    &--container {
        position: fixed;
        height: 60px;
        width: 100%;
        background-color: white;
        display: flex;
        right: 0;
        top: 0;
        z-index: 99;
        align-items: center;
    }

    &--leftpart {
        width: 280px;
        padding: 7px;
        text-align: center;
    }

    &--logo {
        width: 150px;
        cursor: pointer;
        margin-top: 5px;
    }

    &--baseline {
        font-size: 8.6px;
        font-weight: 400;
        line-height: 12px;
        color: $wu-kellygreen;
        display: block;
        margin-top: 2px;
    }

    &--fakesearchbar {
        &__container {
            width: 45%;
            position: relative;
        }

        width: 100%;
        height: 35px;
        border-radius: 10px;
        border: 1px solid $wu-lightergrey;
        padding-left: 35px;

        &::placeholder {
            color: $wu-mediumgrey;
        }

        &:focus {
            outline: 1px solid black;
        }
    }

    &--magnifiericon {
        position: relative;
        left: 25px;
        width: 15px;
        z-index: 1;

        &big {
            display: none;
        }
    }

    &--rightpart {
        display: flex;
        align-items: center;
        position: fixed;
        right: 0;
        height: 60px;
    }

    &--notificon {
        width: 25px;
        height: 25px;
        margin-right: 15px;
        cursor: pointer;
    }

    &--myaccount {
        background-color: $wu-mediumgrey;
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: $wu-darkgrey;
        cursor: pointer;
        width: 165px;
    }

    &--accounticon {
        margin-left: 10px;
    }

    &--accountmenu {
        width: 165px;
        height: 75px;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        border: 1px solid $wu-lightergrey;
        position: fixed;
        right: 0;
        top: 60px;

        &__myprofile {
            margin-bottom: 10px;
        }

        &__item {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &__icon {
            margin-right: 5px;
        }
    }

    &--menu {
        &__iconcontainer {
            display: none;
            text-align: center;
        }

        &__title {
            font-size: 12px;
            font-weight: 600;
            color: $wu-darkgrey;
        }
    }

    &--search {
        &__container {
            background-color: white;
            border-radius: 20px;
            border: 1px solid $wu-mediumdarkgrey;
            z-index: 100;
            position: absolute;
            top: 60px;
            left: calc(50% - 450px);
            width: 900px;
        }

        &__header {
            width: 100%;
            border-bottom: 3px solid $wu-lightergrey;
            display: flex;
        }

        &__title {
            font-size: 12px;
            font-weight: 700;
            line-height: 14.09px;
            letter-spacing: 0.1em;
            padding: 20px 20px 10px 20px;
            display: flex;
            align-items: center;

            &selected {
                color: $wu-bottlegreen;
                border-bottom: 3px solid $wu-bottlegreen;
            }

            &withdata {
                cursor: pointer;
            }
        }

        &__searchcontainer {
            padding: 20px
        }

        &__pastille {
            margin-left: 8px;
            border-radius: 100%;
            background-color: $wu-grey;
            padding: 5px 7px;
            color: white;
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;

            &selected {
                background-color: $wu-bottlegreen;
            }
        }
    }

    &--mobilesearchbar {
        width: calc(100% - 80px) !important;
        display: none;

        &__container {
            position: absolute;
            top: 60px;
            background-color: white;
            width: 100%;
            height: 60px;
            z-index: 1;
            padding: 10px;
            display: none;
        }
    }
}

@media screen and (min-width: 769px) {
    .topbar {
        &--minilogo {
            display: none;
        }

        &--myaccount {
            &__text {
                display: block;
            }
        }

        &--openmobilemenuicon {
            display: none;
        }
    }
}

@media screen and (max-width: 1100px) {
    .topbar {
        &--search {
            &__container {
                width: 800px;
                left: calc(50% - 400px);
            }
        }
    }
}

@media screen and (max-width: 1075px) {
    .topbar {
        &--fakesearchbar {
            width: 40%;
        }
    }
}

@media screen and (max-width: 1023px) {
    .topbar {
        &--menu {
            &__iconcontainer {
                display: block;
            }
        }

        &--container {
            width: 100%;
            left: 0;
        }

        &--openmobilemenuicon {
            display: block;
            width: 25px;
            margin: 0 15px;
        }

        &--magnifiericon {
            display: none;

            &big {
                display: block;
                width: 25px;
            }
        }

        &--leftpart {
            display: none;
        }

        &--fakesearchbar {
            display: none;
        }

        &--minilogo {
            display: block;
            padding: 5px;
            width: 50px;
        }

        &--accounticon {
            margin: 0;
            width: 25px;
        }

        &--myaccount {
            width: 60px;

            &__text {
                display: none;
            }
        }

        &--logo {
            display: none;
        }

        &--baseline {
            display: none;
        }

        &--search {
            &__container {
                top: 120px;
            }
        }

        &--mobilesearchbar {
            display: block;

            &__container {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .topbar {
        &--search {
            &__container {
                width: 600px;
                left: calc(50% - 300px);
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .topbar {
        &--search {
            &__container {
                width: 300px;
                left: calc(50% - 150px);
            }

            &__header {
                flex-direction: column;
            }
        }
    }
}