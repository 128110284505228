@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: currentColor;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.global--button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  background-color: #48AB4D;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}
.global--button__plus {
  font-size: 18px;
  margin-right: 10px;
}
.global--button__margintop8 {
  margin-top: 8px;
}
.global--button__margintop33 {
  margin-top: 33px;
}
.global--button__disabled {
  background-color: #EFEFEF;
  color: #98A1AC;
  cursor: not-allowed;
}
.global--button__goback {
  color: #A1A1AA;
  background-color: white;
  border: 1px solid #A1A1AA;
  margin-right: 10px;
}
.global--button__secondary {
  background-color: white;
  border: 1px solid #48AB4D;
  margin-right: 10px;
  color: #48AB4D;
}
.global--button__black {
  background-color: white;
  border: 1px solid black;
  margin-right: 10px;
  color: black;
}
.global--loader {
  width: 35px;
  height: 35px;
  border: 5px solid #48AB4D;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  margin: auto;
  box-sizing: border-box;
  animation: rotation 1.2s linear infinite;
}
.global--loadersmall {
  width: 10px;
  aspect-ratio: 3;
  background: radial-gradient(circle closest-side, #71717A 90%, rgba(0, 0, 0, 0)) 0/33.3333333333% 100% no-repeat;
  animation: l2 1s steps(3) infinite;
  margin-left: 5px;
}
.global--color__green {
  color: #48AB4D;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes l2 {
  to {
    background-position: 150%;
  }
}
.homepage--container {
  background-color: #FAFAFA;
  display: flex;
  overflow: auto;
  position: fixed;
}
.homepage--publiccontainer {
  height: 100%;
  width: 100%;
}
.homepage--url {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.44px;
  letter-spacing: 0.1em;
  color: #316BFF;
  text-decoration: underline;
  display: block;
  cursor: pointer;
}
.homepage--url__password {
  text-align: right;
  margin-bottom: 18px;
  margin-top: 4px;
}
.homepage--url__createaccount {
  text-align: center;
  margin-top: 6px;
}
.homepage--popup {
  position: relative;
  width: 57%;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  display: flex;
  margin: auto;
  background-color: white;
  max-width: 1000px;
}
.homepage--popup__logo {
  text-align: center;
}
.homepage--popup__logoimg {
  max-width: 100%;
}
.homepage--popup__logopasswordfail {
  width: 100%;
}
.homepage--popup__baseline {
  display: block;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #98A1AC;
  margin-top: 2px;
}
.homepage--popup__leftpart {
  width: 50%;
  padding: 40px 50px;
}
.homepage--popup__img {
  width: 50%;
  border-radius: 0 24px 24px 0;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.2509803922);
}
.homepage--popup__formtitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 25.83px;
  letter-spacing: 0.04em;
  color: #566F8F;
  display: block;
  margin: 10px 0 5px;
}
.homepage--popup__formtitlepasswordfail {
  margin: 15px 0;
}
.homepage--popup__formsubtitle {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #566F8F;
  margin-bottom: 15px;
  display: block;
}
.homepage--popup__formcontainer {
  display: flex;
  flex-direction: column;
}
.homepage--popup__inputlabel {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #566F8F;
  margin: 12px 0 4px 0;
}
.homepage--popup__inputlabelmargintop0 {
  margin-top: 0;
}
.homepage--popup__input {
  border: 1px solid #EFEFEF;
  border-radius: 6px;
  height: 15px;
  padding: 10px;
  width: calc(100% - 25px);
}
.homepage--popup__inputmarginbottom33 {
  margin-bottom: 33px;
}
.homepage--popup__inputmarginbottom14 {
  margin-bottom: 14px;
}
.homepage--popup__inputmarginbottom8 {
  margin-bottom: 8px;
}
.homepage--popup__input::-moz-placeholder {
  color: #EFEFEF;
}
.homepage--popup__input::placeholder {
  color: #EFEFEF;
}
.homepage--popup__inputplaceholder {
  color: #A1A1AA;
}
.homepage--popup__input:focus {
  outline: 1px solid black;
}
.homepage--popup__dropdown {
  height: 36.6px;
  width: 158px;
}
.homepage--popup__icon {
  position: absolute;
  right: 12px;
  top: 42px;
  cursor: pointer;
}
.homepage--popup__iconcrossedout {
  top: 40px;
}
.homepage--popup__passwordinputcontainer {
  width: 100%;
  position: relative;
}
.homepage--popup__noaccount {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #98A1AC;
  display: block;
  margin-top: 20px;
}
.homepage--popup__newsletter {
  display: block;
  position: absolute;
  bottom: -35px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.78px;
  letter-spacing: 0.1em;
  cursor: pointer;
}
.homepage--popup__message {
  display: block;
  font-size: 12px;
  margin: 7px 0;
}
.homepage--popup__messagecenter {
  text-align: center;
}
.homepage--popup__messageerror {
  color: #FF0000;
}
.homepage--popup__messageconfirmation {
  color: #48AB4D;
}
.homepage--popup__multipleinputcontainer {
  display: flex;
}
.homepage--popup__multipleinputcontainermarginbottom {
  margin-bottom: 7px;
}
.homepage--popup__multipleinputcontainermargintop {
  margin-top: 7px;
}
.homepage--popup__insideinputcontainer {
  width: calc(50% - 5px);
}
.homepage--popup__insideinputcontainerleft {
  margin-right: 10px;
}
.homepage--popup__radioinputcontainer {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #566F8F;
  margin-right: 10px;
}
.homepage--popup__radioinputcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.homepage--popup__radioinputcontainer:hover input ~ .homepage--popup__checkmark {
  background-color: white;
}
.homepage--popup__radioinputcontainer input:checked ~ .homepage--popup__checkmark:after {
  display: block;
}
.homepage--popup__radioinputcontainer .homepage--popup__checkmark:after {
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background-color: #566F8F;
}
.homepage--popup__radioinputcontainer input:checked ~ .homepage--popup__checkmark {
  background-color: white;
  border: 1px solid #566F8F;
}
.homepage--popup__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: white;
  border-radius: 50px;
  border: 1px solid #EFEFEF;
}
.homepage--popup__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.homepage--popup__passwordfail {
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.homepage--popup__mandatory {
  font-size: 10px;
  color: #566F8F;
  margin-top: 7px;
}
.homepage--popup__textarea {
  display: block;
  width: calc(100% - 22px);
  border: 1px solid #EFEFEF;
  border-radius: 6px;
  height: 100px;
  margin-bottom: 12px;
  resize: none;
  padding: 10px;
}
.homepage--popup__textarea::-moz-placeholder {
  color: #A1A1AA;
}
.homepage--popup__textarea::placeholder {
  color: #A1A1AA;
}
.homepage--fileinput {
  border: 1px solid #EFEFEF;
  display: inline-block;
  padding: 10px 12px 6px;
  cursor: pointer;
  border-radius: 6px;
  width: 307px;
  color: #A1A1AA;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16.44px;
  letter-spacing: 0.1em;
}
.homepage--fileinput__selected {
  color: #18181B;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #EFEFEF;
  cursor: not-allowed;
}
.homepage--fileinput__container {
  position: relative;
}
.homepage--fileinput__upload {
  position: absolute;
  top: 8px;
  right: 12px;
}
.homepage--fileinput__showdocument {
  cursor: pointer;
  top: 11px;
}
.homepage--checkbox {
  margin: 0;
}
.homepage--checkbox__container {
  display: flex;
  margin-top: 5px;
}
.homepage--checkbox__label {
  margin: 0 0 0 5px;
}
.homepage--button__confirmation {
  margin: 0 auto 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.homepage--color__black {
  color: black;
}

input[type=checkbox] {
  accent-color: #566F8F;
}

input[type=file] {
  display: none;
}

@media screen and (max-width: 1023px) {
  .homepage--popup {
    width: 80%;
  }
  .homepage--popup__leftpart {
    width: 100%;
    padding: 25px 20px;
  }
  .homepage--popup__img {
    display: none;
  }
  .homepage--popup__logoimg {
    width: 150px;
  }
  .homepage--popup__newsletter {
    bottom: -55px;
  }
}
@media screen and (max-width: 370px) {
  .homepage--popup__formtitle {
    font-size: 19px;
  }
  .homepage--popup__inputlabel {
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
  .homepage--fileinput {
    width: 220px;
  }
  .homepage--fileinput__upload {
    display: none;
  }
}
.informationpopup--background {
  background-color: rgba(161, 161, 170, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
}
.informationpopup--container {
  background-color: white;
  border: 2px solid #D9D9D9;
  border-radius: 10px;
  position: fixed;
  z-index: 100;
}
.informationpopup--containernewsletter {
  width: 540px;
  top: calc(50% - 187px);
  left: calc(50% - 270px);
  padding: 50px 88px;
}
.informationpopup--containernewsletter__confirmation {
  top: calc(50% - 83.5px);
}
.informationpopup--containernewsletter__suppr {
  width: 840px;
  top: calc(50% - 117.5px);
  left: calc(50% - 420px);
  padding: 50px;
}
.informationpopup--containernewsletter__sepa {
  width: 600px;
  left: calc(50% - 300px);
  top: calc(50% - 84px);
  text-align: center;
}
.informationpopup--containernewsletter__information {
  padding: 30px 40px;
  width: 600px;
  left: calc(50% - 300px);
  top: calc(50% - 127px);
  text-align: center;
}
.informationpopup--title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.informationpopup--title__center {
  text-align: center;
}
.informationpopup--subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
}
.informationpopup--subtitle__margintop {
  margin-top: 15px;
}
.informationpopup--text {
  font-size: 12px;
  margin: 15px 0;
}
.informationpopup--formcontainer {
  display: flex;
  flex-direction: column;
}
.informationpopup--input {
  border: 1px solid #566F8F;
  border-radius: 4px;
  padding: 10px 12px;
}
.informationpopup--input::-moz-placeholder {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #A1A1AA;
}
.informationpopup--input::placeholder {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #A1A1AA;
}
.informationpopup--input__title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #566F8F;
  margin-top: 15px;
  margin-bottom: 5px;
}
.informationpopup--lastinput {
  margin-bottom: 18px;
}
.informationpopup--margintop15 {
  margin-top: 15px;
}
.informationpopup--close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 16px;
}
.informationpopup--buttoncontainer {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .informationpopup--containernewsletter__suppr {
    width: 520px;
    top: calc(50% - 150px);
    left: calc(50% - 260px);
  }
}
@media screen and (max-width: 768px) {
  .informationpopup--containernewsletter__sepa {
    width: 500px;
    top: calc(50% - 100px);
    left: calc(50% - 250px);
  }
}
@media screen and (max-width: 620px) {
  .informationpopup--containernewsletter__information {
    width: 450px;
    top: calc(50% - 152px);
    left: calc(50% - 225px);
  }
}
@media screen and (max-width: 540px) {
  .informationpopup--containernewsletter {
    width: 350px;
    top: calc(50% - 219px);
    left: calc(50% - 175px);
    padding: 30px 25px;
  }
  .informationpopup--containernewsletter__confirmation {
    top: calc(50% - 80px);
  }
  .informationpopup--containernewsletter__suppr {
    width: 300px;
    top: calc(50% - 183px);
    left: calc(50% - 150px);
  }
  .informationpopup--title {
    font-size: 18px;
  }
}
@media screen and (max-width: 470px) {
  .informationpopup--containernewsletter__information {
    width: 300px;
    top: calc(50% - 162px);
    left: calc(50% - 150px);
  }
}
@media screen and (max-width: 320px) {
  .informationpopup--containernewsletter {
    width: 300px;
    top: calc(50% - 199px);
    left: calc(50% - 150px);
    padding: 30px 25px;
  }
  .informationpopup--containernewsletter__confirmation {
    top: calc(50% - 87.5px);
  }
}
.pageloader--turbine {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  height: 100%;
  width: 100%;
}

.pageloader--pole,
.pageloader--pilot {
  grid-row: 1;
  grid-column: 1;
}

.pageloader--pole {
  position: relative;
  display: block;
  align-self: end;
  background-color: dimgrey;
  height: 45%;
  width: 10px;
  border-radius: 5px 5px 0 0;
  z-index: 0;
}

.pageloader--pilot {
  position: relative;
  z-index: 1;
}

.pageloader--pilot:after {
  content: "";
  display: block;
  position: absolute;
  top: 40px;
  left: -6px;
  z-index: 1;
  height: 27px;
  width: 27px;
  border: 4px solid #FAFAFA;
  border-radius: 50%;
  box-sizing: border-box;
}

.pageloader--pilot:after,
.pageloader--pilot .pageloader--prop {
  background-color: #48AB4D;
}

.pageloader--pilot .pageloader--prop-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  justify-items: center;
  animation: propeller 1.5s infinite linear;
  transform-origin: 7px 53px;
}

.pageloader--pilot .pageloader--prop {
  height: 50px;
  width: 14px;
  border-radius: 50%;
  grid-column: 1;
  grid-row: 1;
  transform-origin: 50% 50%;
}

.pageloader--prop:first-child {
  transform: rotate(360deg) translate(0px) rotate(-360deg);
}

.pageloader--prop:nth-child(2) {
  transform: rotateZ(120deg) rotate(120deg) translate(-50px) rotate(-120deg);
}

.pageloader--prop:last-child {
  transform: rotateZ(240deg) rotate(240deg) translate(50px) rotate(-240deg);
}

@keyframes propeller {
  to {
    transform: rotateZ(360deg);
  }
}
.sidebar--container {
  width: 280px;
  position: absolute;
  background-color: white;
  padding: 20px 30px;
  font-family: Plus Jakarta Sans, sans-serif;
  color: #3F3F46;
  left: 0;
  text-align: center;
  height: calc(100% - 120px);
  overflow: auto;
  top: 60px;
  z-index: 99;
}
.sidebar--addbutton {
  margin: 14px 0 24px;
}
.sidebar--listelement {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.sidebar--listicon {
  margin-right: 15px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.sidebar--elementtitle {
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;
}
.sidebar--elementtitle__active {
  font-weight: 700;
}
.sidebar--listtitle {
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  color: #A1A1AA;
}
.sidebar--listtitle__container {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.sidebar--newenergybutton {
  width: 220px;
  margin-top: 30px;
  cursor: pointer;
}
.sidebar--logout {
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  position: fixed;
  bottom: 0;
  cursor: pointer;
  background-color: white;
  width: 280px;
  padding: 20px 30px;
  left: 0;
}
.sidebar--dropdownicon {
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .sidebar--container {
    display: none;
  }
  .sidebar--containermobile {
    display: block;
    padding: 10px 20px;
    width: 245px;
    height: calc(100% - 60px);
    transition: all 0.5s ease;
  }
  .sidebar--logo {
    display: none;
  }
  .sidebar--baseline {
    display: none;
  }
}
.topbar--container {
  position: fixed;
  height: 60px;
  width: 100%;
  background-color: white;
  display: flex;
  right: 0;
  top: 0;
  z-index: 99;
  align-items: center;
}
.topbar--leftpart {
  width: 280px;
  padding: 7px;
  text-align: center;
}
.topbar--logo {
  width: 150px;
  cursor: pointer;
  margin-top: 5px;
}
.topbar--baseline {
  font-size: 8.6px;
  font-weight: 400;
  line-height: 12px;
  color: #8FBE44;
  display: block;
  margin-top: 2px;
}
.topbar--fakesearchbar {
  width: 100%;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #EFEFEF;
  padding-left: 35px;
}
.topbar--fakesearchbar__container {
  width: 45%;
  position: relative;
}
.topbar--fakesearchbar::-moz-placeholder {
  color: #A1A1AA;
}
.topbar--fakesearchbar::placeholder {
  color: #A1A1AA;
}
.topbar--fakesearchbar:focus {
  outline: 1px solid black;
}
.topbar--magnifiericon {
  position: relative;
  left: 25px;
  width: 15px;
  z-index: 1;
}
.topbar--magnifiericonbig {
  display: none;
}
.topbar--rightpart {
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  height: 60px;
}
.topbar--notificon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.topbar--myaccount {
  background-color: #A1A1AA;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #3F3F46;
  cursor: pointer;
  width: 165px;
}
.topbar--accounticon {
  margin-left: 10px;
}
.topbar--accountmenu {
  width: 165px;
  height: 75px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border: 1px solid #EFEFEF;
  position: fixed;
  right: 0;
  top: 60px;
}
.topbar--accountmenu__myprofile {
  margin-bottom: 10px;
}
.topbar--accountmenu__item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.topbar--accountmenu__icon {
  margin-right: 5px;
}
.topbar--menu__iconcontainer {
  display: none;
  text-align: center;
}
.topbar--menu__title {
  font-size: 12px;
  font-weight: 600;
  color: #3F3F46;
}
.topbar--search__container {
  background-color: white;
  border-radius: 20px;
  border: 1px solid #E4E4E7;
  z-index: 100;
  position: absolute;
  top: 60px;
  left: calc(50% - 450px);
  width: 900px;
}
.topbar--search__header {
  width: 100%;
  border-bottom: 3px solid #EFEFEF;
  display: flex;
}
.topbar--search__title {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  padding: 20px 20px 10px 20px;
  display: flex;
  align-items: center;
}
.topbar--search__titleselected {
  color: #48AB4D;
  border-bottom: 3px solid #48AB4D;
}
.topbar--search__titlewithdata {
  cursor: pointer;
}
.topbar--search__searchcontainer {
  padding: 20px;
}
.topbar--search__pastille {
  margin-left: 8px;
  border-radius: 100%;
  background-color: #71717A;
  padding: 5px 7px;
  color: white;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topbar--search__pastilleselected {
  background-color: #48AB4D;
}
.topbar--mobilesearchbar {
  width: calc(100% - 80px) !important;
  display: none;
}
.topbar--mobilesearchbar__container {
  position: absolute;
  top: 60px;
  background-color: white;
  width: 100%;
  height: 60px;
  z-index: 1;
  padding: 10px;
  display: none;
}

@media screen and (min-width: 769px) {
  .topbar--minilogo {
    display: none;
  }
  .topbar--myaccount__text {
    display: block;
  }
  .topbar--openmobilemenuicon {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .topbar--search__container {
    width: 800px;
    left: calc(50% - 400px);
  }
}
@media screen and (max-width: 1075px) {
  .topbar--fakesearchbar {
    width: 40%;
  }
}
@media screen and (max-width: 1023px) {
  .topbar--menu__iconcontainer {
    display: block;
  }
  .topbar--container {
    width: 100%;
    left: 0;
  }
  .topbar--openmobilemenuicon {
    display: block;
    width: 25px;
    margin: 0 15px;
  }
  .topbar--magnifiericon {
    display: none;
  }
  .topbar--magnifiericonbig {
    display: block;
    width: 25px;
  }
  .topbar--leftpart {
    display: none;
  }
  .topbar--fakesearchbar {
    display: none;
  }
  .topbar--minilogo {
    display: block;
    padding: 5px;
    width: 50px;
  }
  .topbar--accounticon {
    margin: 0;
    width: 25px;
  }
  .topbar--myaccount {
    width: 60px;
  }
  .topbar--myaccount__text {
    display: none;
  }
  .topbar--logo {
    display: none;
  }
  .topbar--baseline {
    display: none;
  }
  .topbar--search__container {
    top: 120px;
  }
  .topbar--mobilesearchbar {
    display: block;
  }
  .topbar--mobilesearchbar__container {
    display: block;
  }
}
@media screen and (max-width: 850px) {
  .topbar--search__container {
    width: 600px;
    left: calc(50% - 300px);
  }
}
@media screen and (max-width: 650px) {
  .topbar--search__container {
    width: 300px;
    left: calc(50% - 150px);
  }
  .topbar--search__header {
    flex-direction: column;
  }
}
.privatepages--container {
  top: 60px;
  left: 280px;
  width: calc(100% - 280px);
  height: calc(100% - 60px);
  padding: 30px 40px 60px;
  display: block;
}
.privatepages--text {
  font-family: Plus Jakarta Sans, sans-serif;
  color: #18181B;
}
.privatepages--title {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 5px;
}
.privatepages--separator {
  border: 1px solid #EFEFEF;
  margin-top: 8px;
}
.privatepages--asterisk {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 12px;
  display: block;
  margin-top: 20px;
}
.privatepages--columnscontainer {
  display: flex;
}
.privatepages--twocolumns {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.privatepages--twocolumns__left {
  margin-right: 50px;
}
.privatepages--twocolumns__right {
  margin-top: 31px;
}
.privatepages--form__block {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  background-color: white;
  margin-top: 6px;
  padding: 15px 20px;
  width: 380px;
}
.privatepages--form__blocksiteslist {
  min-width: 745px;
}
.privatepages--form__title {
  font-size: 16px;
  font-weight: 700;
}
.privatepages--form__subtitle {
  font-family: Plus Jakarta Sans;
  font-size: 9px;
  font-style: italic;
  font-weight: 300;
  line-height: 9px;
}
.privatepages--eyeicon {
  top: 30px;
}
.privatepages--eyeicon__crossedout {
  top: 28px;
}
.privatepages--button {
  margin-top: 12px;
}
.privatepages--header__container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.privatepages--header__return {
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: #48AB4D;
  font-size: 14px;
  font-family: Plus Jakarta Sans, sans-serif;
}

@media screen and (max-width: 1520px) {
  .privatepages--columnscontainer__contributors {
    flex-direction: column;
  }
}
@media screen and (max-width: 1439px) {
  .privatepages--columnscontainer {
    flex-direction: column;
  }
}
@media screen and (max-width: 1200px) {
  .privatepages--columnscontainer {
    align-items: center;
  }
  .privatepages--twocolumns__left {
    margin: 0;
  }
  .privatepages--twocolumns__right {
    margin-bottom: 60px;
    width: auto;
  }
}
@media screen and (max-width: 1023px) {
  .privatepages--form__blocksiteslist {
    min-width: 100%;
    width: 700px;
  }
  .privatepages--container {
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
  }
}
@media screen and (max-width: 768px) {
  .privatepages--header__container {
    flex-direction: column;
  }
  .privatepages--header__return {
    text-align: right;
  }
}
@media screen and (max-width: 700px) {
  .privatepages--form__block {
    width: 400px;
  }
}
@media screen and (max-width: 400px) {
  .privatepages--form__block {
    width: 300px;
  }
}
@media screen and (max-width: 377px) {
  .privatepages--header__return {
    font-size: 11px;
  }
}
.notfound--container {
  display: flex;
  padding: 40px 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  height: calc(100% - 60px);
}
.notfound--title {
  font-size: 80px;
  font-weight: 700;
  line-height: 93.92px;
  letter-spacing: 0.04em;
  text-align: center;
}
.notfound--paragraph {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.13px;
  letter-spacing: 0.1em;
  margin-top: 12px;
  text-align: center;
}

@media screen and (max-width: 426px) {
  .notfound--title {
    font-size: 58px;
    line-height: 70px;
  }
  .notfound--paragraph {
    font-size: 16px;
  }
}
.table--link {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-decoration: underline;
  color: #48AB4D;
  cursor: pointer;
}
.table--icon {
  margin-left: 5px;
  cursor: pointer;
}
.table--icon__container {
  display: flex;
  justify-content: center;
}
.table--tablecontainer {
  width: 100%;
  background-color: white;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  margin-top: 16px;
  position: relative;
  min-height: 300px;
  padding: 22px;
  font-weight: 500;
}
.table--tablecontainer__button {
  width: -moz-fit-content;
  width: fit-content;
}
.table--searchbarcontainer {
  display: flex;
  margin-bottom: 33px;
  justify-content: space-between;
  position: relative;
}
.table--searchbarcontainer__siteslist {
  margin: 16px 0;
}
.table--addbutton {
  position: absolute;
  right: 22px;
  top: 22px;
}
.table--checkicon {
  margin-left: 12px;
}
.table--sendicon {
  cursor: pointer;
}
.table--sent {
  padding: 4px 0;
}
.table--head__container {
  display: flex;
  position: relative;
}
.table--alignright {
  text-align: right;
}
.table--flexend {
  justify-content: flex-end;
}
.table--cell__limited {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .table--searchbarcontainer {
    flex-direction: column-reverse;
  }
  .table--form {
    width: calc(100% - 40px) !important;
    margin-top: 12px;
  }
  .table--addbutton {
    position: initial;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1050px) {
  .table--addbutton {
    position: initial;
    margin-bottom: 15px;
  }
}
.searchbar--container {
  position: relative;
}
.searchbar--magnifiericon {
  position: absolute;
  top: 8px;
  left: 10px;
}
.searchbar--bar {
  background-color: #FAFAFA;
  width: 100%;
  height: 28px;
  border-radius: 10px;
  border: 1px solid #EFEFEF;
  padding-left: 35px;
}
.searchbar--bar::-moz-placeholder {
  color: #A1A1AA;
}
.searchbar--bar::placeholder {
  color: #A1A1AA;
}
.searchbar--bar:focus {
  outline: 1px solid black;
}
.searchbar--siteslist {
  width: 55%;
}
.searchbar--form {
  width: 30%;
}

.messages--form__block {
  margin-top: 16px;
  width: 600px;
}
.messagestable--newmessagelink {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
  color: #48AB4D;
  border: 1px solid #48AB4D;
  text-transform: uppercase;
  border-radius: 35px;
  padding: 5px 15px;
}
.messagestable--emptycell {
  width: 146px;
  display: block;
  color: white;
}
.messages--chattitle {
  display: flex;
}
.messages--chatsubtitle {
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 700px;
}
.messages--chatcontainer {
  width: 730px;
  padding: 20px 0;
  height: calc(100% - 185px);
  position: relative;
  overflow: auto;
}
.messages--chat__columnscontainer {
  height: calc(100% - 40px);
}
.messages--chatdate {
  font-family: Raleway;
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: right;
  display: block;
}
.messages--chatcontent {
  border-radius: 10px;
  padding: 20px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
}
.messages--chat__line {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  white-space: pre-wrap;
}
.messages--chat__lineclient {
  align-items: flex-end;
}
.messages--chat__client {
  background-color: #b5eca0;
}
.messages--chat__wattvalue {
  background-color: #E4E4E7;
}
.messages--chat__form {
  position: fixed;
  bottom: 30px;
  width: 740px;
  left: 310px;
  background-color: #FAFAFA;
}

@media screen and (max-width: 1440px) {
  .messages--chatsubtitle {
    width: 300px;
  }
  .messages--chat__columnscontainer {
    width: 730px;
  }
}
@media screen and (max-width: 1100px) {
  .messages--chatcontainer {
    width: 600px;
    height: calc(100% - 250px);
  }
  .messages--chat__columnscontainer {
    width: 600px;
  }
  .messages--chat__form {
    width: 600px;
  }
}
@media screen and (max-width: 1023px) {
  .messages--chat__form {
    left: 40px;
  }
}
@media screen and (max-width: 768px) {
  .messages--chatcontainer {
    width: 400px;
  }
  .messages--chat__columnscontainer {
    width: 400px;
  }
  .messages--chat__form {
    width: 400px;
  }
}
@media screen and (max-width: 660px) {
  .messages--form__block {
    width: 400px;
  }
}
@media screen and (max-width: 460px) {
  .messages--form__block {
    width: 250px;
  }
  .messages--chatcontainer {
    width: 250px;
  }
  .messages--chat__columnscontainer {
    width: 250px;
  }
  .messages--chat__form {
    width: 250px;
  }
}
@media screen and (max-height: 500px) {
  .messages--chat__form {
    position: initial;
  }
  .messages--chatcontainer {
    height: 100%;
    overflow: initial;
  }
}
.mandates--container {
  padding-bottom: 20px;
}
.mandates--header {
  display: flex;
  cursor: pointer;
}
.mandates--header__arrow {
  margin-bottom: 12px;
}
.mandates--header__searchbar {
  margin-bottom: 12px;
}
.mandates--site__title {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: 700;
  color: #18181B;
  margin-bottom: 10px;
}
.mandates--link {
  display: block;
  max-width: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 7px;
}
.mandates--link__container {
  display: flex;
}
.mandates--linknomargin {
  margin: 0;
}
.mandates--file {
  cursor: pointer;
  display: flex;
}
.mandates--block__container {
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.sepa--header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sepa--header__clientname {
  font-size: 26px;
  font-weight: 700;
  line-height: 16px;
  color: #18181B;
  margin: 24px 0;
}
.sepa--form__import {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.09px;
  letter-spacing: 0.02em;
  color: #48AB4D;
  display: block;
  cursor: pointer;
  margin-top: 5px;
}
.sepa--form__importdisabled {
  cursor: not-allowed;
}
.sepa--form__dropdown {
  width: 220px;
  margin-right: 12px;
}
.sepa--form__dropdowncontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.sepa--form__block {
  width: 370px;
  position: relative;
}
.sepa--form__blockbottom {
  margin-top: 20px;
}
.sepa--form__inputcontainer {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.sepa--form__uploadcontainer {
  margin-top: 12px;
  position: relative;
}
.sepa--middlecolumn {
  margin-top: 32px;
  margin-left: 35px;
  margin-right: 35px;
}
.sepa--thirdcolumn {
  margin-top: 32px;
}
.sepa--link {
  max-width: 135px;
}
.sepa--button__add {
  color: #48AB4D;
  background-color: white;
  border: 1px solid #48AB4D;
  position: relative;
}
.sepa--recipients {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  display: block;
  margin: 10px 0;
  cursor: pointer;
  color: #18181B;
}
.sepa--recipients__container {
  display: flex;
  align-items: center;
}
.sepa--recipients__icon {
  margin-left: 5px;
}
.sepa--emptyrecipients {
  font-size: 13px;
  margin: 5px 0;
  display: block;
}
.sepa--emailinput {
  width: calc(100% - 50px);
}
.sepa--messageinfo {
  margin: 0;
  text-align: right;
}
.sepa--buttonscontainer {
  display: flex;
}
.sepa--table__title {
  padding: 0 10px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1em;
  display: block;
  margin-bottom: 12px;
}
.sepa--table__searchbarcontainer {
  margin-bottom: 15px;
}
.sepa--table__searchbarcontainerbottom {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .sepa--header__container {
    justify-content: center;
    margin-top: 15px;
  }
  .sepa--header__clientname {
    display: none;
  }
  .sepa--messageinfo {
    text-align: center;
    margin-top: 7px;
  }
}
@media screen and (max-width: 450px) {
  .sepa--form__upload {
    width: 230px;
  }
}
@media screen and (max-width: 376px) {
  .sepa--form__block {
    width: 300px;
  }
  .sepa--form__upload {
    width: 176px;
  }
}
.greenenergy--title {
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 18px;
  display: block;
}
.greenenergy--paragraph {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-align: justify;
  margin: 48px 0;
}
.greenenergy--paragraph__bold {
  font-weight: 700;
}
.greenenergy--showmore {
  font-size: 12px;
  font-weight: 700;
  color: #48AB4D;
  cursor: pointer;
}
.greenenergy--list__item {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 13px;
}
.greenenergy--list__number {
  font-weight: 700;
  color: #48AB4D;
}
.greenenergy--moreinfo {
  color: #48AB4D;
  font-weight: 700;
  cursor: pointer;
}
.greenenergy--block {
  border: 1px solid #EFEFEF;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.greenenergy--block__left {
  width: 70%;
}
.greenenergy--block__right {
  width: 28%;
  text-align: center;
}
.greenenergy--block__container {
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
}
.greenenergy--block__img {
  width: 280px;
  max-width: 100%;
}
.greenenergy--block__imgsubtitle {
  font-size: 18px;
  font-weight: 400;
  margin-top: 15px;
  display: block;
}
.greenenergy--maptitle {
  width: 100%;
  display: block;
  text-align: center;
}

@media screen and (max-width: 750px) {
  .greenenergy--block__container {
    flex-direction: column;
  }
  .greenenergy--block__left {
    width: 100%;
  }
  .greenenergy--block__right {
    width: 100%;
    margin-top: 16px;
  }
}
.elec--title {
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.1em;
  margin-bottom: 6px;
  word-wrap: break-word;
}
.elec--paragraph {
  margin-bottom: 15px;
  line-height: 20px;
}
.elec--paragraph__withbutton {
  margin: 0 20px 0 0;
}
.elec--paragraph__container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.elec--link {
  color: #48AB4D;
  text-decoration: underline;
  cursor: pointer;
}
.elec--anomalie {
  color: #FF0000;
}
.elec--filter__searchbar {
  width: 330px;
  height: 35px;
  margin-right: 15px;
  max-width: 82%;
}
.elec--filter__container {
  display: flex;
  margin-bottom: 10px;
}
.elec--filter__dropdown {
  width: 174px;
}
.elec--emptystate {
  margin-top: 20px;
  display: block;
}
.elec--table__title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-top: 10px;
}
.elec--table__titlemargintop {
  margin-top: 25px;
}
.elec--separator {
  border-color: black;
}
.elec--center {
  justify-content: center;
}
.elec--upload__subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1em;
  margin: 30px 0 8px;
}
.elec--upload__info {
  font-size: 13px;
  display: block;
}
.elec--upload__button {
  margin-top: 12px;
}
.elec--informationblock {
  display: flex;
  background-color: white;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  margin-top: 16px;
  padding: 10px 22px;
}
.elec--informationblock__img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.elec--informationblock__title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.elec--add__invoicetitle {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #566F8F;
}
.elec--add__invoicesubtitle {
  display: block;
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  line-height: 11.74px;
  letter-spacing: 0.1em;
  color: #566F8F;
}
.elec--add__link {
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
.elec--form__inputcontainer {
  margin-top: 3px;
}

@media screen and (max-width: 768px) {
  .elec--informationblock {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .elec--filter__container {
    flex-direction: column;
  }
  .elec--filter__dropdown {
    margin-top: 10px;
  }
}
.gaz--searchbar {
  margin-bottom: 33px;
}
.gaz--searchbarcontainer__emptyarray {
  justify-content: flex-end;
}

@media screen and (max-width: 1100px) {
  .gaz--searchbar {
    width: calc(100% - 40px) !important;
    margin-top: 12px;
  }
  .gaz--searchbarcontainer {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 392px) {
  .gaz--addbutton {
    margin-bottom: 12px;
  }
}
.addsites--choosetype__container {
  margin: 15px 0 10px;
}
.addsites--choosetype__title {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.addsites--choosetype__switchcontainer {
  display: flex;
  align-items: center;
}
.addsites--choosetype__energy {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 400;
}
.addsites--choosetype__energyactive {
  font-weight: 700;
  color: #48AB4D;
}
.addsites--step__title {
  font-family: Plus Jakarta Sans;
  font-size: 26px;
  font-weight: 700;
  color: #18181B;
  margin: 30px 0 20px;
}
.addsites--step__titlecontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addsites--step__titleright {
  font-weight: 400;
}
.addsites--step__button {
  height: -moz-fit-content;
  height: fit-content;
}
.addsites--dropdown__container {
  display: flex;
  align-items: flex-end;
}
.addsites--dropdown__eleccontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.addsites--dropdown__elecdropdown {
  width: 68%;
}
.addsites--importbutton {
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 12px;
  margin-right: 0;
}
.addsites--enddate__label {
  font-size: 12px;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #566F8F;
  display: block;
  margin: 12px 0 4px;
  font-weight: 500;
}
.addsites--enddate__disabled {
  background-color: #EFEFEF;
  font-size: 14px;
  font-style: italic;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #566F8F;
  display: block;
  border-radius: 6px;
  padding: 10px 15px;
  width: -moz-fit-content;
  width: fit-content;
}
.addsites--enddate__cliquable {
  cursor: pointer;
}
.addsites--enddate__subtitle {
  font-size: 9px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.1em;
  display: block;
  color: #566F8F;
  margin-bottom: 6px;
}
.addsites--calendar__container {
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid #EFEFEF;
  border-radius: 6px;
  z-index: 99;
  top: 0;
  right: -310px;
  text-align: right;
}
.addsites--link {
  font-family: Plus Jakarta Sans;
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
  color: #48AB4D;
  text-decoration: underline;
  margin: 4px 0;
  display: block;
  cursor: pointer;
}
.addsites--notabene {
  font-family: Plus Jakarta Sans;
  font-size: 11px;
  font-style: italic;
  font-weight: 300;
  line-height: 11px;
  display: block;
  margin-bottom: 12px;
}
.addsites--bottomtext {
  margin-top: 5px;
  width: 380px;
}
.addsites--intro {
  display: block;
  text-align: center;
}
.addsites--radiogroup__trv {
  margin-left: 12px;
}
.addsites--prices__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
}
.addsites--prices__block {
  width: 28%;
  padding: 25px;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 230px;
  max-width: 350px;
  margin-top: 20px;
}
.addsites--prices__text {
  font-size: 14px;
  letter-spacing: 0.1em;
  text-align: center;
}
.addsites--prices__price {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 8px;
}
.addsites--date {
  min-width: 117px;
  min-height: 38px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1305px) {
  .addsites--prices__container {
    justify-content: center;
  }
  .addsites--prices__block {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1439px) {
  .addsites--calendar__container {
    right: 34px;
    top: 335px;
  }
}
@media screen and (max-width: 700px) {
  .addsites--step__titlecontainer {
    flex-direction: column;
  }
  .addsites--bottomtext {
    width: 280px;
  }
}
@media screen and (max-width: 376px) {
  .addsites--calendar__container {
    right: 0;
  }
  .addsites--prices__block {
    margin-right: 0;
  }
}
.appeloffre--header__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}
.appeloffre--header__containerleft {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.appeloffre--header__link {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #18181B;
  cursor: pointer;
  margin-left: 20px;
  text-align: right;
}
.appeloffre--header__icon {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}
.appeloffre--header__title {
  margin: 0;
}
.appeloffre--header__linkscontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.appeloffre--header__pastille {
  padding: 3px 8px;
  border-radius: 35px;
  margin-left: 15px;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  cursor: default;
  text-wrap: nowrap;
}
.appeloffre--header__pastilleinscriptionencours {
  background-color: #48AB4D;
  color: white;
  width: 155px;
}
.appeloffre--header__pastilletourindicatifencours {
  background-color: white;
  color: #48AB4D;
  border: 1px solid #48AB4D;
  width: 180px;
}
.appeloffre--header__pastilleresultats {
  color: black;
  background-color: #D9D9D9;
  width: 220px;
}
.appeloffre--header__pastilleresultatsindicatifs {
  width: 230px;
  background-color: white;
  border: 1px solid #FF0000;
  color: #FF0000;
}
.appeloffre--header__pastillereengagement {
  border: 1px solid #48AB4D;
  color: #48AB4D;
  width: 182px;
  display: flex;
  align-items: center;
  position: relative;
}
.appeloffre--header__dropdownicon {
  margin-left: 5px;
}
.appeloffre--header__spacebetween {
  justify-content: space-between;
}
.appeloffre--header__width240 {
  min-width: 240px;
}
.appeloffre--modal__container {
  padding: 30px;
  width: 426px;
  left: calc(50% - 213px);
  top: calc(50% - 191px);
}
.appeloffre--modal__title {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 14px;
  color: #18181B;
  margin-bottom: 15px;
}
.appeloffre--modal__text {
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  color: #566F8F;
  margin-bottom: 5px;
}
.appeloffre--modal__date {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.1em;
}
.appeloffre--title {
  font-size: 18px;
  margin-top: 30px;
}
.appeloffre--result__left {
  width: calc(100% - 320px);
  min-height: 170px;
}
.appeloffre--result__right {
  width: 300px;
  margin-left: 20px;
  background-color: #48AB4D;
  border-radius: 10px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13px;
  font-weight: 400;
  flex-direction: column;
  font-family: Plus Jakarta Sans;
}
.appeloffre--result__car {
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
}
.appeloffre--result__top {
  display: flex;
}
.appeloffre--result__type {
  font-size: 16px;
  font-weight: 400;
}
.appeloffre--result__value {
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
}
.appeloffre--result__valuecontainer {
  margin-bottom: 10px;
}
.appeloffre--result__texte {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: justify;
  margin-bottom: 8px;
  white-space: pre-wrap;
}
.appeloffre--result__fournisseur {
  display: block;
  color: #18181B;
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 10px;
}
.appeloffre--result__date {
  display: block;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 20px;
}
.appeloffre--result__list {
  list-style-type: disc;
  margin-left: 25px;
}
.appeloffre--result__listsecond {
  list-style-type: circle;
  margin-left: 45px;
}
.appeloffre--result__listthird {
  list-style-type: square;
  margin-left: 65px;
}
.appeloffre--result__price {
  font-weight: 700;
}
.appeloffre--result__indicatif {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  padding: 3px 8px;
  border: 1px solid #FF0000;
  border-radius: 35px;
  color: #FF0000;
  text-transform: uppercase;
}
.appeloffre--reengagement__title {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  color: #48AB4D;
  margin: 8px 0;
  display: block;
}
.appeloffre--reengagement__icon {
  margin-right: 5px;
}
.appeloffre--indicatif__container {
  display: flex;
  justify-content: space-between;
}
.appeloffre--indicatif__containergaz {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 16px;
       column-gap: 16px;
}
.appeloffre--indicatif__block {
  width: 49%;
}
.appeloffre--indicatif__subtitle {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  color: #18181B;
  display: block;
}
.appeloffre--indicatif__title {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 700;
  color: #18181B;
  display: block;
  margin: 10px 0;
}
.appeloffre--indicatif__asterisque {
  margin-bottom: 0;
}
.appeloffre--indicatif__report {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  display: block;
  margin-bottom: 15px;
}
.appeloffre--indicatif__reportbutton {
  background-color: #E4E4E7;
  color: black;
}
.appeloffre--indicatif__checktext {
  font-size: 12px;
  font-weight: 400;
  padding-left: 5px;
}
.appeloffre--indicatif__checkcontainer {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
.appeloffre--indicatif__checkbutton {
  border: 1px solid black;
  background-color: white;
  color: black;
  margin-top: 20px;
}

@media screen and (max-width: 1530px) {
  .appeloffre--header__containerleft {
    flex-direction: column;
    align-items: flex-start;
    min-width: 245px;
  }
  .appeloffre--header__pastille {
    margin-left: 0;
    margin-top: 5px;
  }
}
@media screen and (max-width: 800px) {
  .appeloffre--header__container {
    flex-direction: column;
    align-items: flex-start;
  }
  .appeloffre--header__linkscontainer {
    margin-top: 10px;
  }
  .appeloffre--header__linkfirst {
    margin-left: 0;
  }
}
@media screen and (max-width: 745px) {
  .appeloffre--result__top {
    flex-direction: column-reverse;
  }
  .appeloffre--result__left {
    width: 100%;
  }
  .appeloffre--result__right {
    width: 100%;
    margin: 16px 0 0 0;
    height: 125px;
  }
  .appeloffre--indicatif__container {
    flex-direction: column;
  }
  .appeloffre--indicatif__containergaz {
    display: flex;
    flex-direction: column;
  }
  .appeloffre--indicatif__block {
    width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .appeloffre--modal__container {
    width: 365px;
    left: calc(50% - 181px);
    top: calc(50% - 100px);
  }
}
@media screen and (max-width: 375px) {
  .appeloffre--modal__container {
    width: 300px;
    left: calc(50% - 150px);
    top: calc(50% - 120px);
  }
  .appeloffre--result__car {
    font-size: 30px;
  }
}
.conso--nonresponsivecontainer {
  min-width: 1080px;
}
.conso--containers {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
}
.conso--containers__bottom {
  align-items: flex-start;
  position: relative;
}
.conso--containers__bottomtitle {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
}
.conso--containers__leftcolumn {
  width: 58%;
}
.conso--containers__rightcolumns {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
.conso--containers__rightcolumnsbottom {
  width: 45%;
  flex-direction: column;
}
.conso--containers__searchbar {
  background-color: white;
}
.conso--header__right {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  padding: 0 15px;
}
.conso--header__subtitle {
  font-family: Plus Jakarta Sans;
  font-size: 9px;
  font-style: italic;
  font-weight: 300;
  line-height: 10px;
  text-align: center;
  color: #18181B;
}
.conso--header__subtitleleft {
  margin-right: 12px;
}
.conso--header__periode {
  height: 30px;
  width: 100%;
}
.conso--header__periodedropdowns {
  display: flex;
  align-items: center;
}
.conso--header__periodedropdownsdiv {
  margin: 0 5px;
}
.conso--greyblock {
  width: 100%;
  display: flex;
  margin-top: 0;
  padding: 12px 15px;
  justify-content: space-between;
  position: relative;
  background-color: #FAFAFA;
  z-index: 1;
}
.conso--greyblockright:after {
  content: "";
  position: absolute;
  z-index: 99;
  top: 38px;
  bottom: 0;
  left: 32px;
  border-left: 1px dashed #3F3F46;
  height: calc(100% - 160px);
}
.conso--button {
  font-size: 12px;
  margin: 0;
  padding: 4px 8px;
  width: 80px;
  margin-top: 5.06px;
  height: 27px;
}
.conso--button__first {
  margin-top: 41px;
}
.conso--buttonoption {
  color: black;
  border: none;
  cursor: default;
  background-color: transparent;
}
.conso--buttonoption__first {
  margin-top: 41px;
}
.conso--buttonoption__withborder {
  border: 1px solid black;
  cursor: pointer;
  background-color: white;
}
.conso--button__send {
  margin: auto;
  padding: 10px 20px;
}
.conso--emptyblock {
  height: 32px;
}
.conso--export__text {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  color: #18181B;
}
.conso--checkbox__container {
  flex-direction: column;
}
.conso--checkbox__prm {
  margin-left: 15px;
}
.conso--checkbox__first {
  margin-bottom: 14px;
}
.conso--switch__container {
  margin: 30px auto 18px;
}
.conso--dateinput {
  cursor: pointer;
  background-color: #E4E4E7;
  padding: 2px 3px;
  border-radius: 5px;
  margin: 0 3px;
}
.conso--calendar__container {
  top: calc(50% - 188px);
  right: calc(50% - 151px);
}
.conso--legend {
  display: flex;
  cursor: default;
  font-size: 14px;
}
.conso--legend__container {
  display: grid;
  max-width: 600px;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}
.conso--legend__mark {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  margin: 0 4px 0 7px;
}
.conso--legend__mark1 {
  background-color: #0a6273;
}
.conso--legend__mark2 {
  background-color: #007c7e;
}
.conso--legend__mark3 {
  background-color: #009578;
}
.conso--legend__mark4 {
  background-color: #47ac63;
}
.conso--legend__mark5 {
  background-color: #8fbe44;
}
.conso--chart__container {
  height: 360px;
  width: 100%;
}
.conso--detail__container {
  display: flex;
  margin-top: 32px;
  z-index: -1;
  position: relative;
  justify-content: space-between;
}
.conso--detail__containerwithalert {
  justify-content: flex-start;
}
.conso--detail__block {
  width: 350px;
  min-height: 260px;
  border: 1px solid #EFEFEF;
  padding: 20px;
  border-radius: 10px;
  margin-right: 35px;
  background-color: white;
  position: relative;
}
.conso--detail__blockthird {
  margin-right: 0;
}
.conso--detail__title {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  color: #18181B;
  display: block;
  margin-bottom: 15px;
}
.conso--detail__alerttitle {
  margin-bottom: 0;
}
.conso--detail__switchcontainer {
  margin-bottom: 15px;
}
.conso--detail__subtitle {
  font-size: 12px;
  margin: 5px 0;
}
.conso--detail__text {
  font-size: 11px;
  font-weight: 400;
  color: #71717A;
}
.conso--detail__paragraph {
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
  line-height: 14px;
  color: #71717A;
  white-space: pre-wrap;
}
.conso--detail__enedis {
  font-family: Plus Jakarta Sans;
  font-size: 9px;
  font-weight: 500;
  line-height: 12px;
  color: #71717A;
  position: absolute;
  bottom: 15px;
}
.conso--detail__enedisbold {
  font-weight: 700;
}
.conso--detail__today {
  bottom: 30px;
}
.conso--detail__activation {
  bottom: 45px;
}
.conso--detail__list {
  display: flex;
  margin-bottom: 5px;
}
.conso--detail__linklist {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.conso--detail__linklistparagraph {
  display: inline-block;
}
.conso--detail__data {
  font-size: 12px;
  color: #71717A;
  display: block;
  margin-bottom: 5px;
}
.conso--detail__datacontainer {
  margin: 15px 0;
  padding-bottom: 30px;
}
.conso--detail__databold {
  font-weight: 700;
}
.conso--sitename {
  max-width: 250px;
}
.conso--filters__button {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
  color: #18181B;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.conso--filters__container {
  font-family: Plus Jakarta Sans;
  color: #18181B;
  width: 270px;
  position: absolute;
  right: 0;
  background-color: white;
  z-index: 2;
  padding: 25px;
  top: 70px;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  max-height: 450px;
  overflow: auto;
}
.conso--filters__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.conso--filters__subtitle {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  display: block;
}
.conso--filters__radioinputcontainer {
  margin-top: 7px;
  color: black;
}
.conso--filters__radioinputcontainer .conso--filters__checkmark:after {
  background-color: black;
}
.conso--filters__radioinputcontainer input:checked ~ .conso--filters__checkmark {
  border: 1px solid black;
}
.conso--filters__valuecontainers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.conso--filters__valuecontainersweeks {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 3px;
}
.conso--filters__label {
  color: black;
}
.conso--filters__selectall {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 500;
  margin-left: 5px;
  color: #3F3F46;
  cursor: pointer;
}
.conso--activatebutton {
  margin-right: 10px;
}
.conso--alert__dropdown {
  width: 90px;
}
.conso--alert__dropdowncontainer {
  display: inline-block;
  margin: 0 5px 0 0;
}
.conso--recipients {
  margin: 0 0 10px 0;
  word-break: break-all;
}
.conso--recipients__block {
  margin-top: 18px;
}
.conso--recipients__container {
  align-items: flex-start;
  justify-content: space-between;
}
.conso--tooltip__container {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  border-radius: 8px;
}
.conso--tooltip__title {
  color: #18181B;
  margin-bottom: 4px;
}
.conso--tooltip__listitem {
  padding: 4px 0;
}
.conso--choice {
  font-size: 11px;
}

hr {
  border: none;
  border-top: 2px dashed #E4E4E7;
  color: white;
  background-color: white;
  height: 1px;
  position: absolute;
  left: 10px;
  z-index: 0;
  width: 85%;
}

.hr--1 {
  top: 85px;
}
.hr--2 {
  top: 117px;
}
.hr--3 {
  top: 149px;
}
.hr--4 {
  top: 181px;
}
.hr--5 {
  top: 213px;
}
.hr--6 {
  top: 245px;
}
.hr--7 {
  top: 277px;
}
.hr--8 {
  top: 309px;
}
.hr--9 {
  top: 341px;
}
.hr--10 {
  top: 373px;
}
.hr--11 {
  top: 405px;
}
.hr--12 {
  top: 437px;
}
.hr--13 {
  top: 469px;
}
.hr--14 {
  top: 501px;
}
.hr--15 {
  top: 533px;
}
.hr--16 {
  top: 565px;
}
.hr--17 {
  top: 597px;
}
.hr--18 {
  top: 629px;
}
.hr--19 {
  top: 661px;
}
.hr--20 {
  top: 693px;
}
.hr--21 {
  top: 725px;
}
.hr--22 {
  top: 757px;
}
.hr--23 {
  top: 789px;
}
.hr--24 {
  top: 821px;
}
.hr--25 {
  top: 853px;
}
.hr--26 {
  top: 885px;
}
.hr--27 {
  top: 917px;
}
.hr--28 {
  top: 949px;
}
.hr--29 {
  top: 989px;
}
.hr--30 {
  top: 1013px;
}
.hr--31 {
  top: 1045px;
}
.hr--32 {
  top: 1077px;
}
.hr--33 {
  top: 1109px;
}
.hr--34 {
  top: 1141px;
}
.hr--35 {
  top: 1173px;
}
.hr--36 {
  top: 1205px;
}
.hr--37 {
  top: 1237px;
}
.hr--38 {
  top: 1269px;
}
.hr--39 {
  top: 1301px;
}
.hr--40 {
  top: 1333px;
}
.hr--41 {
  top: 1365px;
}
.hr--42 {
  top: 1397px;
}
.hr--43 {
  top: 1429px;
}
.hr--44 {
  top: 1461px;
}
.hr--45 {
  top: 1493px;
}
.hr--46 {
  top: 1525px;
}
.hr--47 {
  top: 1557px;
}
.hr--48 {
  top: 1589px;
}
.hr--49 {
  top: 1621px;
}
.hr--50 {
  top: 1653px;
}

#table--head__container-0 {
  width: 66px !important;
}

@media screen and (max-width: 1807px) {
  .conso--buttonoption__first {
    margin-top: 29px;
  }
}
@media screen and (max-width: 1470px) {
  .conso--containers__legend {
    flex-direction: column;
  }
  .conso--legend__container {
    margin: 15px 0;
  }
}
@media screen and (max-width: 1440px) {
  .conso--detail__today {
    bottom: 45px;
  }
  .conso--detail__activation {
    bottom: 60px;
  }
}
@media screen and (max-width: 900px) {
  .conso--detail__block {
    width: 100%;
    margin-right: 0;
  }
  .conso--detail__blocksecond {
    margin-top: 20px;
  }
  .conso--detail__blockthird {
    margin-top: 20px;
  }
  .conso--detail__container {
    flex-direction: column;
  }
}
@media screen and (max-width: 860px) {
  .conso--detail__blocksecond {
    margin-right: 0;
  }
}
@media screen and (max-width: 620px) {
  .conso--header__periodedropdowns {
    flex-direction: column;
    align-items: flex-start;
  }
  .conso--export__button {
    margin: 15px 0;
  }
}
@media screen and (max-width: 550px) {
  .conso--filters__container {
    top: 215px;
    left: 0;
  }
}
@media screen and (max-width: 425px) {
  .conso--legend {
    margin-bottom: 4px;
  }
  .conso--legend__container {
    flex-direction: column;
    align-items: flex-start;
  }
  .conso--filters__container {
    top: 240px;
    left: 0;
  }
}
@media screen and (max-width: 335px) {
  .conso--filters__container {
    top: 260px;
  }
}
@media screen and (min-width: 1441px) {
  .conso--detail__container {
    justify-content: flex-start;
  }
}
.tooltip--container {
  position: absolute;
  align-items: center;
  z-index: 101;
  min-height: 39px;
}
.tooltip--container__right {
  right: -170px;
}
.tooltip--container__left {
  left: -170px;
}
.tooltip--container__text {
  display: inline-block;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #48AB4D;
  font-family: Plus Jakarta Sans;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  color: white;
  width: 170px;
  padding: 5px;
  border-radius: 5px;
  text-wrap: auto;
}
.tooltip--container__white {
  background-color: white;
  color: #71717A;
  border: 1px solid #E4E4E7;
  padding: 10px 5px;
}
.tooltip--container__triangle {
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.tooltip--container__triangleright {
  border-right: 10px solid #48AB4D;
}
.tooltip--container__triangleleft {
  border-left: 10px solid #48AB4D;
}

@media screen and (max-width: 740px) {
  .tooltip--container__form {
    left: -18px !important;
    margin-top: 60px !important;
    flex-direction: column;
  }
  .tooltip--container__right {
    right: 0;
  }
  .tooltip--container__left {
    left: 0;
    right: 0;
  }
  .tooltip--container__triangleright {
    display: none;
  }
  .tooltip--container__triangleleft {
    display: none;
  }
  .tooltip--container__triangletop {
    border-right: 10px solid transparent;
    border-bottom: 10px solid #48AB4D;
    border-left: 10px solid transparent;
    display: block;
  }
}
.subscription--container {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
.subscription--container__block {
  width: 48%;
}
.subscription--container__emptystate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 15px;
}
.subscription--left__title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-bottom: 15px;
  line-height: 20px;
}
.subscription--left__value {
  font-weight: 400;
}
.subscription--left__prm {
  margin-bottom: 0;
  margin-top: 28px;
}
.subscription--left__bold {
  font-weight: 700;
}
.subscription--left__paragraph {
  margin-bottom: 15px;
  line-height: 20px;
}
.subscription--left__list {
  line-height: 20px;
}
.subscription--right {
  border: 1px solid #E4E4E7;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  height: -moz-fit-content;
  height: fit-content;
}
.subscription--right__price {
  font-family: Plus Jakarta Sans;
  font-size: 38px;
  font-weight: 700;
}
.subscription--right__pricetext {
  font-weight: 200;
}
.subscription--right__subtitle {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  display: block;
  margin: 15px 0;
}
.subscription--right__check {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  margin-top: 5px;
}
.subscription--right__button {
  margin-top: 20px;
}
.subscription--link {
  color: #48AB4D;
  text-decoration: underline;
  cursor: pointer;
}
.subscription--video__container {
  overflow: hidden;
  margin-top: 15px;
}
.subscription--video__container iframe {
  height: 315px;
  width: 560px;
}

@media screen and (max-width: 767px) {
  .subscription--container {
    flex-direction: column;
  }
  .subscription--container__block {
    width: 100%;
  }
  .subscription--right {
    margin-top: 15px;
  }
}
@media screen and (max-width: 645px) {
  .subscription--video__container iframe {
    height: auto;
    width: 100%;
  }
}
.turpe--subtitle {
  display: block;
  margin: 20px 0;
}
.turpe--explanation {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #A1A1AA;
}
.turpe--columns__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.turpe--column__left {
  width: 36%;
}
.turpe--column__right {
  width: 25%;
}
.turpe--column__title {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-weight: 700;
  color: #18181B;
  margin-bottom: 14px;
  display: block;
}
.turpe--block {
  border: 1px solid #E4E4E7;
  padding: 20px 20px 40px 20px;
  border-radius: 10px;
  min-height: 229px;
  position: relative;
}
.turpe--block__left {
  background-color: white;
}
.turpe--block__right {
  font-family: Plus Jakarta Sans;
  background-color: #48AB4D;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.turpe--block__cost {
  display: block;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.1em;
  margin-top: 20px;
}
.turpe--block__costmargintop {
  margin-top: 40px;
}
.turpe--block__listitem {
  list-style-type: disc;
  display: list-item;
  margin-left: 15px;
  margin-top: 5px;
}
.turpe--block__listitembold {
  font-weight: 700;
}
.turpe--optimisee {
  color: #48AB4D;
  font-weight: 700;
}
.turpe--more {
  color: #48AB4D;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.1em;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
}
.turpe--economy {
  display: block;
  font-size: 42px;
  font-weight: 700;
  overflow-wrap: anywhere;
}
.turpe--economy__text {
  display: block;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
}
.turpe--economy__asterisque {
  font-size: 10px;
  margin-top: 5px;
}
.turpe--radioinput__container {
  font-size: 16px;
  line-height: 14px;
  color: black;
  margin-bottom: 12px;
}
.turpe--radioinput__container .conso--filters__checkmark:after {
  background-color: black;
}
.turpe--radioinput__container input:checked ~ .conso--filters__checkmark {
  border: 1px solid black;
}
.turpe--radioinput__container .hturpe--radioinput__checkmark:after {
  background-color: black;
}
.turpe--radioinput__containerreadonly {
  cursor: not-allowed;
}
.turpe--withtravaux {
  display: block;
  margin: 20px 0 15px;
}
.turpe--withouttravaux__paragraph {
  line-height: 22px;
  letter-spacing: 0.1em;
  margin-top: 20px;
}
.turpe--withouttravaux__listitem {
  margin-left: 10px;
  line-height: 22px;
  letter-spacing: 0.1em;
}
.turpe--withouttravaux__container {
  background-color: #E4E4E7;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  line-height: 22px;
  letter-spacing: 0.1em;
}
.turpe--button__validate {
  margin-top: 15px;
}

@media screen and (max-width: 1300px) {
  .turpe--columns__container {
    flex-direction: column-reverse;
  }
  .turpe--column__left {
    width: 100%;
    margin-bottom: 15px;
  }
  .turpe--column__right {
    width: 100%;
    margin-bottom: 15px;
  }
}
.resources--block {
  border: 1px solid #EFEFEF;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.resources--block__title {
  display: block;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #18181B;
}
.resources--blocks__container {
  display: flex;
  margin: 16px 0;
  justify-content: space-between;
}
.resources--block__link {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  color: #566F8F;
  margin-top: 10px;
  display: block;
  cursor: pointer;
}
.resources--block__newsletters {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 1px;
  color: #3F3F46;
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
}
.resources--input {
  border: 1px solid #566F8F;
  color: #566F8F;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  font-style: italic;
  line-height: 14.09px;
  letter-spacing: 0.1em;
  cursor: pointer;
  position: relative;
}
.resources--input__icon {
  position: absolute;
  right: 10px;
}
.resources--column {
  width: 31%;
}

@media screen and (max-width: 1400px) {
  .resources--block {
    width: 100%;
  }
  .resources--blocks__container {
    flex-direction: column;
    align-items: center;
  }
  .resources--column {
    width: 100%;
  }
}
.dashboard--header__container {
  display: flex;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  line-height: 26px;
  color: #71717A;
}
.dashboard--header__name {
  font-weight: 700;
  display: block;
  margin-right: 5px;
  color: #18181B;
}
.dashboard--header__switchcontainer {
  margin-left: 50px;
}
.dashboard--emptystate {
  font-family: Plus Jakarta Sans;
  line-height: 26px;
  color: #71717A;
  font-weight: 400;
}
.dashboard--button {
  margin-top: 12px;
}
.dashboard--indicators {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard--indicators__top {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.dashboard--indicators__left {
  width: 69%;
  margin: 0;
}
.dashboard--indicators__right {
  width: 29%;
}
.dashboard--indicators__righttop {
  margin: 0;
  height: 100%;
}
.dashboard--indicators__block {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #EFEFEF;
  padding: 20px;
  height: 100%;
  min-height: 119px;
}
.dashboard--indicators__blocklarge {
  width: 65%;
}
.dashboard--indicators__blocksmall {
  width: 35%;
}
.dashboard--indicators__blockfull {
  width: 100%;
}
.dashboard--indicators__blockcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard--indicators__blockcontainerleft {
  width: 68%;
  height: 100%;
}
.dashboard--indicators__blockcontainerright {
  width: 32%;
  height: 100%;
}
.dashboard--indicators__blockcontainerappeloffre {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard--indicators__blockleft {
  margin-right: 20px;
}
.dashboard--indicators__blocktitle {
  font-family: Plus Jakarta Sans;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  color: #71717A;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.dashboard--indicators__blocksubtitle {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  line-height: 8px;
  color: #71717A;
  display: block;
}
.dashboard--indicators__blocknumber {
  font-family: Plus Jakarta Sans;
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
  color: #18181B;
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.dashboard--indicators__blockmargintop6 {
  margin-top: 6px;
}
.dashboard--indicators__blockmargintop14 {
  margin-top: 14px;
}
.dashboard--indicators__blockicon {
  margin-right: 5px;
}
.dashboard--indicators__loadercontainer {
  display: flex;
  align-items: flex-end;
}
.dashboard--number {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}
.dashboard--number__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}
.dashboard--number__up {
  color: #FF0000;
}
.dashboard--number__down {
  color: #48AB4D;
}
.dashboard--addsite {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 8px;
  color: #71717A;
  text-transform: uppercase;
  cursor: pointer;
}
.dashboard--addsite__date {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 11px;
}
.dashboard--addsite__container {
  margin-top: 8px;
}
.dashboard--appeloffres__container {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  align-items: center;
}
.dashboard--appeloffres__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard--appeloffres__icon {
  margin-right: 12px;
}
.dashboard--appeloffres__title {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  color: #18181B;
}
.dashboard--appeloffres__subtitle {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  color: #71717A;
  display: block;
}
.dashboard--appeloffres__result {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  color: #18181B;
  cursor: pointer;
}
.dashboard--seemore {
  margin-top: 20px;
  cursor: pointer;
  position: absolute;
  bottom: 22px;
}
.dashboard--conso__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard--conso__headercontainer {
  margin-bottom: 15px;
}
.dashboard--conso__button {
  border: 1px solid #D4D4D8;
  background-color: white;
  color: #18181B;
  width: 215px;
}
.dashboard--paddingbottom54 {
  padding-bottom: 54px;
}
.dashboard--graph__container {
  min-height: 455px;
}
.dashboard--marginright10 {
  margin-right: 10px;
}
.dashboard--legend__container {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
}
.dashboard--positionrelative {
  position: relative;
}

@media screen and (max-width: 1530px) {
  .dashboard--conso__header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .dashboard--conso__button {
    margin-top: 10px;
  }
  .dashboard--conso__title {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1400px) {
  .dashboard--appeloffres__container {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1200px) {
  .dashboard--indicators__blockleft {
    margin-right: 0;
  }
  .dashboard--indicators__blockleftinside {
    margin-bottom: 15px;
  }
  .dashboard--indicators__blocklarge {
    width: 100%;
  }
  .dashboard--indicators__blocksmall {
    width: 100%;
  }
  .dashboard--indicators__blockcontainerup {
    flex-direction: column;
    margin-right: 15px;
  }
  .dashboard--addsite__container {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard--addsite__date {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1023px) {
  .dashboard--addsite__container {
    flex-direction: row;
    align-items: center;
  }
  .dashboard--addsite__date {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 900px) {
  .dashboard--indicators__right {
    width: 34%;
  }
  .dashboard--indicators__left {
    width: 64%;
  }
  .dashboard--conso__container {
    flex-direction: column;
  }
  .dashboard--conso__top {
    width: 100% !important;
  }
  .dashboard--conso__bottom {
    width: 100% !important;
    margin-top: 15px;
  }
  .dashboard--graph__container {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
}
@media screen and (max-width: 750px) {
  .dashboard--header__container {
    flex-direction: column;
  }
  .dashboard--header__switch {
    margin-left: 0;
  }
  .dashboard--indicators__blockcontainer {
    flex-direction: column;
  }
  .dashboard--indicators__blockcontainerleft {
    width: 100%;
  }
  .dashboard--indicators__blockcontainerup {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .dashboard--indicators__blockcontainerright {
    width: 100%;
  }
  .dashboard--indicators__blocklarge {
    margin-bottom: 15px;
  }
  .dashboard--indicators__right {
    width: 49%;
  }
  .dashboard--indicators__left {
    width: 49%;
  }
  .dashboard--appeloffres__container {
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (max-width: 550px) {
  .dashboard--indicators__left {
    width: 100%;
  }
  .dashboard--indicators__blocklarge {
    width: 100%;
  }
  .dashboard--indicators__top {
    flex-direction: column;
  }
  .dashboard--indicators__right {
    width: 100%;
  }
  .dashboard--indicators__righttop {
    width: 100%;
    margin-top: 15px;
  }
}
.budget--more {
  left: 40px;
  bottom: initial;
  font-size: 16px;
  margin-top: 5px;
}
.budget--indicators__top {
  margin-top: 30px;
}
.budget--indicators__blocknumbers {
  justify-content: space-between;
}
.budget--indicators__righttop {
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 96px;
  margin: 0;
}
.budget--indicators__rightbottom {
  height: calc(100% - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.budget--indicators__title {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #18181B;
  display: block;
}
.budget--graph__container {
  margin: 20px 0;
}
.budget--graph__containerbyyear {
  display: flex;
  margin-bottom: 7px;
}
.budget--graph__containerbyyearcamembert {
  flex-direction: column;
}
.budget--nodata {
  display: block;
  margin-top: 15px;
}
.budget--table__title {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #18181B;
  display: block;
  margin-bottom: 10px;
}
.budget--table__data {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  display: block;
  color: #18181B;
}
.budget--table__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.budget--checkall {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  color: #18181B;
}
.budget--sitesarray__container {
  position: relative;
}
.budget--site__container {
  margin-top: 25px;
}
.budget--site__header {
  margin-left: 10px;
  margin-top: 10px;
}
.budget--site__headercheckbox {
  display: flex;
  align-items: center;
}
.budget--line__vertical:after {
  content: "";
  position: absolute;
  z-index: 99;
  top: 27px;
  bottom: 0;
  left: 17px;
  border-left: 1px dashed #3F3F46;
}
.budget--legend__camembert {
  align-items: center;
  margin-bottom: 5px;
}
.budget--dropdown {
  margin-right: 15px;
}

@media screen and (max-width: 1120px) {
  .budget--graph__containerbyyear {
    flex-direction: column;
  }
  .budget--legend {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 550px) {
  .budget--indicators__righttop {
    margin-top: 16px;
  }
  .budget--filtres__container {
    top: 80px;
  }
  .budget--table__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 335px) {
  .budget--filtres__container {
    top: 90px;
  }
  .budget--searchbar {
    width: auto;
  }
}/*# sourceMappingURL=index.css.map */