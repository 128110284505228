.resources {
    &--block {
        border: 1px solid $wu-lightergrey;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        margin-bottom: 20px;

        &__title {
            display: block;
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: $wu-darkergrey;
        }

        &s__container {
            display: flex;
            margin: 16px 0;
            justify-content: space-between;
        }

        &__link {
            font-size: 12px;
            font-weight: 500;
            line-height: 14.09px;
            letter-spacing: 0.1em;
            color: $wu-bluegrey;
            margin-top: 10px;
            display: block;
            cursor: pointer;
        }

        &__newsletters {
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 1px;
            color: $wu-darkgrey;
            margin-top: 20px;
            display: block;
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    &--input {
        border: 1px solid $wu-bluegrey;
        color: $wu-bluegrey;
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        font-size: 12px;
        font-style: italic;
        line-height: 14.09px;
        letter-spacing: 0.1em;
        cursor: pointer;
        position: relative;

        &__icon {
            position: absolute;
            right: 10px;
        }
    }

    &--column {
        width: 31%;
    }
}

@media screen and (max-width: 1400px) {
    .resources {
        &--block {
            width: 100%;

            &s__container {
                flex-direction: column;
                align-items: center;
            }
        }

        &--column {
            width: 100%;
        }
    }
}