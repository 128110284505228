//Backgrounds
$wu-lightgrey: #FAFAFA;

//Fonts
$wu-grey: #71717A;
$wu-darkgrey: #3F3F46;
$wu-darkergrey: #18181B;
$wu-mediumgrey: #A1A1AA;
$wu-mediumdarkgrey: #E4E4E7;
$wu-bluegrey: #566F8F;
$wu-slategrey: #98A1AC;
$wu-magentablue: #316BFF;
$wu-salmonred: #FF7051;
$wu-errorred: #FF0000;
$wu-pastelgreen: #b5eca0;

//Buttons
$wu-lightergrey: #EFEFEF;
$wu-bottlegreen: #48AB4D;

//Graphs
$wu-kellygreen: #8FBE44;