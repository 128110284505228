.turpe {
    &--subtitle {
        display: block;
        margin: 20px 0;
    }

    &--explanation {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1em;
        color: $wu-mediumgrey;
    }

    &--column {
        &s__container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        &__left {
            width: 36%;
        }

        &__right {
            width: 25%;
        }

        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 18px;
            font-weight: 700;
            color: $wu-darkergrey;
            margin-bottom: 14px;
            display: block;
        }
    }

    &--block {
        border: 1px solid $wu-mediumdarkgrey;
        padding: 20px 20px 40px 20px;
        border-radius: 10px;
        min-height: 229px;
        position: relative;

        &__left {
            background-color: white;
        }

        &__right {
            font-family: Plus Jakarta Sans;
            background-color: #48AB4D;
            color: white;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__cost {
            display: block;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.1em;
            margin-top: 20px;

            &margintop {
                margin-top: 40px;
            }
        }

        &__list {
            &item {
                list-style-type: disc;
                display: list-item;
                margin-left: 15px;
                margin-top: 5px;

                &bold {
                    font-weight: 700
                }
            }
        }
    }

    &--optimisee {
        color: $wu-bottlegreen;
        font-weight: 700;
    }

    &--more {
        color: $wu-bottlegreen;
        font-size: 10px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.1em;
        cursor: pointer;
        position: absolute;
        bottom: 15px;
    }

    &--economy {
        display: block;
        font-size: 42px;
        font-weight: 700;
        overflow-wrap: anywhere;

        &__text {
            display: block;
            font-size: 12px;
            line-height: 15px;
            margin-top: 10px;
        }

        &__asterisque {
            font-size: 10px;
            margin-top: 5px;
        }
    }

    &--radioinput {
        &__container {
            font-size: 16px;
            line-height: 14px;
            color: black;
            margin-bottom: 12px;

            & .conso--filters__checkmark:after {
                background-color: black;
            }

            & input:checked~.conso--filters__checkmark {
                border: 1px solid black;
            }

            & .hturpe--radioinput__checkmark:after {
                background-color: black;
            }

            &readonly {
                cursor: not-allowed;
            }
        }
    }

    &--withtravaux {
        display: block;
        margin: 20px 0 15px;
    }

    &--withouttravaux {
        &__paragraph {
            line-height: 22px;
            letter-spacing: 0.1em;
            margin-top: 20px;
        }

        &__listitem {
            margin-left: 10px;
            line-height: 22px;
            letter-spacing: 0.1em;
        }

        &__container {
            background-color: $wu-mediumdarkgrey;
            border-radius: 10px;
            margin-top: 20px;
            padding: 20px;
            line-height: 22px;
            letter-spacing: 0.1em;
        }
    }

    &--button {
        &__validate {
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .turpe {
        &--columns {
            &__container {
                flex-direction: column-reverse;
            }
        }

        &--column {
            &__left {
                width: 100%;
                margin-bottom: 15px;
            }

            &__right {
                width: 100%;
                margin-bottom: 15px;
            }
        }

    }
}