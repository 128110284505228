.gaz {
    &--searchbar {
        margin-bottom: 33px;

        &container {
            &__emptyarray {
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .gaz {
        &--searchbar {
            width: calc(100% - 40px) !important;
            margin-top: 12px;
        }

        &--searchbarcontainer {
            flex-direction: column-reverse;
        }
    }
}

@media screen and (max-width: 392px) {
    .gaz {
        &--addbutton {
            margin-bottom: 12px;
        }
    }
}