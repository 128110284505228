.notfound {
    &--container {
        display: flex;
        padding: 40px 50px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: auto;
        height: calc(100% - 60px);
    }

    &--title {
        font-size: 80px;
        font-weight: 700;
        line-height: 93.92px;
        letter-spacing: 0.04em;
        text-align: center;
    }

    &--paragraph {
        font-size: 18px;
        font-weight: 400;
        line-height: 21.13px;
        letter-spacing: 0.1em;
        margin-top: 12px;
        text-align: center;
    }
}

@media screen and (max-width: 426px) {
    .notfound {
        &--title {
            font-size: 58px;
            line-height: 70px;
        }

        &--paragraph {
            font-size: 16px;
            ;
        }
    }
}