.informationpopup {

    &--background {
        background-color: rgb(161, 161, 170, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
    }

    &--container {
        background-color: white;
        border: 2px solid #D9D9D9;
        border-radius: 10px;
        position: fixed;
        z-index: 100;

        &newsletter {
            width: 540px;
            top: calc(50% - 187px);
            left: calc(50% - 270px);
            padding: 50px 88px;

            &__confirmation {
                top: calc(50% - 83.5px);
            }

            &__suppr {
                width: 840px;
                top: calc(50% - 117.5px);
                left: calc(50% - 420px);
                padding: 50px;
            }

            &__sepa {
                width: 600px;
                left: calc(50% - 300px);
                top: calc(50% - 84px);
                text-align: center;
            }

            &__information {
                padding: 30px 40px;
                width: 600px;
                left: calc(50% - 300px);
                top: calc(50% - 127px);
                text-align: center;
            }
        }
    }

    &--title {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0.1em;

        &__center {
            text-align: center;
        }
    }

    &--subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.1em;

        &__margintop {
            margin-top: 15px;
        }
    }

    &--text {
        font-size: 12px;
        margin: 15px 0;
    }

    &--formcontainer {
        display: flex;
        flex-direction: column;
    }

    &--input {
        border: 1px solid #566F8F;
        border-radius: 4px;
        padding: 10px 12px;

        &::placeholder {
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: 14.09px;
            letter-spacing: 0.1em;
            color: $wu-mediumgrey;
        }

        &__title {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.1em;
            color: $wu-bluegrey;
            margin-top: 15px;
            margin-bottom: 5px;
        }
    }

    &--lastinput {
        margin-bottom: 18px;
    }

    &--margintop15 {
        margin-top: 15px;
    }

    &--close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        width: 16px;
    }

    &--buttoncontainer {
        display: flex;
        margin-top: 30px;
        justify-content: center;
    }
}

@media screen and (max-width: 900px) {
    .informationpopup {
        &--container {
            &newsletter {

                &__suppr {
                    width: 520px;
                    top: calc(50% - 150px);
                    left: calc(50% - 260px);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .informationpopup {
        &--container {
            &newsletter {

                &__sepa {
                    width: 500px;
                    top: calc(50% - 100px);
                    left: calc(50% - 250px);
                }
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .informationpopup {
        &--container {
            &newsletter {

                &__information {
                    width: 450px;
                    top: calc(50% - 152px);
                    left: calc(50% - 225px);
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .informationpopup {
        &--container {
            &newsletter {
                width: 350px;
                top: calc(50% - 219px);
                left: calc(50% - 175px);
                padding: 30px 25px;

                &__confirmation {
                    top: calc(50% - 80px);
                }

                &__suppr {
                    width: 300px;
                    top: calc(50% - 183px);
                    left: calc(50% - 150px);
                }
            }
        }

        &--title {
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 470px) {
    .informationpopup {
        &--container {
            &newsletter {

                &__information {
                    width: 300px;
                    top: calc(50% - 162px);
                    left: calc(50% - 150px);
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .informationpopup {
        &--container {
            &newsletter {
                width: 300px;
                top: calc(50% - 199px);
                left: calc(50% - 150px);
                padding: 30px 25px;

                &__confirmation {
                    top: calc(50% - 87.5px);
                }
            }
        }
    }
}