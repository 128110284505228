.conso {
    &--nonresponsivecontainer {
        min-width: 1080px;

    }

    &--containers {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 15px;

        &__bottom {
            align-items: flex-start;
            position: relative;

            &title {
                font-family: Plus Jakarta Sans;
                font-size: 10px;
                font-weight: 700;
                line-height: 12px;
                text-align: center;
            }
        }

        &__leftcolumn {
            width: 58%;
        }

        &__rightcolumns {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;

            &bottom {
                width: 45%;
                flex-direction: column;
            }
        }

        &__searchbar {
            background-color: white;
        }
    }

    &--header {
        &__right {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            width: 100%;
            padding: 0 15px;
        }

        &__subtitle {
            font-family: Plus Jakarta Sans;
            font-size: 9px;
            font-style: italic;
            font-weight: 300;
            line-height: 10px;
            text-align: center;
            color: $wu-darkergrey;

            &left {
                margin-right: 12px;
            }
        }

        &__periode {
            height: 30px;
            width: 100%;

            &dropdowns {
                display: flex;
                align-items: center;

                &div {
                    margin: 0 5px;
                }
            }
        }
    }

    &--greyblock {
        width: 100%;
        display: flex;
        margin-top: 0;
        padding: 12px 15px;
        justify-content: space-between;
        position: relative;
        background-color: #FAFAFA;
        z-index: 1;

        &right {
            &:after {
                content: "";
                position: absolute;
                z-index: 99;
                top: 38px;
                bottom: 0;
                left: 32px;
                border-left: 1px dashed #3F3F46;
                height: calc(100% - 160px);
            }
        }
    }

    &--button {
        font-size: 12px;
        margin: 0;
        padding: 4px 8px;
        width: 80px;
        margin-top: 5.06px;
        height: 27px;

        &__first {
            margin-top: 41px;
        }

        &option {
            color: black;
            border: none;
            cursor: default;
            background-color: transparent;

            &__first {
                margin-top: 41px;
            }

            &__withborder {
                border: 1px solid black;
                cursor: pointer;
                background-color: white;
            }
        }

        &__send {
            margin: auto;
            padding: 10px 20px;
        }
    }

    &--emptyblock {
        height: 32px;
    }

    &--export {
        &__text {
            font-family: Plus Jakarta Sans;
            font-size: 10px;
            font-weight: 300;
            line-height: 12px;
            color: $wu-darkergrey;
        }
    }

    &--checkbox {
        &__container {
            flex-direction: column;
        }

        &__prm {
            margin-left: 15px;
        }

        &__first {
            margin-bottom: 14px;
        }
    }

    &--switch {
        &__container {
            margin: 30px auto 18px;
        }
    }

    &--dateinput {
        cursor: pointer;
        background-color: $wu-mediumdarkgrey;
        padding: 2px 3px;
        border-radius: 5px;
        margin: 0 3px;
    }

    &--calendar {
        &__container {
            top: calc(50% - 188px);
            right: calc(50% - 151px);
        }
    }

    &--legend {
        display: flex;
        cursor: default;
        font-size: 14px;

        &__container {
            display: grid;
            max-width: 600px;
            grid-gap: 5px;
            grid-template-columns: repeat(4, 1fr);
        }

        &__mark {
            width: 15px;
            height: 15px;
            border-radius: 100px;
            margin: 0 4px 0 7px;

            &1 {
                background-color: #0a6273;
            }

            &2 {
                background-color: #007c7e;
            }

            &3 {
                background-color: #009578;
            }

            &4 {
                background-color: #47ac63;
            }

            &5 {
                background-color: #8fbe44;
            }
        }
    }

    &--chart {
        &__container {
            height: 360px;
            width: 100%;
        }
    }

    &--detail {
        &__container {
            display: flex;
            margin-top: 32px;
            z-index: -1;
            position: relative;
            justify-content: space-between;

            &withalert {
                justify-content: flex-start;
            }
        }

        &__block {
            width: 350px;
            min-height: 260px;
            border: 1px solid $wu-lightergrey;
            padding: 20px;
            border-radius: 10px;
            margin-right: 35px;
            background-color: white;
            position: relative;

            &third {
                margin-right: 0;
            }
        }

        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 700;
            color: $wu-darkergrey;
            display: block;
            margin-bottom: 15px;
        }

        &__alerttitle {
            margin-bottom: 0;
        }

        &__switchcontainer {
            margin-bottom: 15px;
        }

        &__subtitle {
            font-size: 12px;
            margin: 5px 0;
        }

        &__text {
            font-size: 11px;
            font-weight: 400;
            color: #71717A;
        }

        &__paragraph {
            font-size: 13px;
            font-weight: 400;
            margin-top: 10px;
            line-height: 14px;
            color: #71717A;
            white-space: pre-wrap;
        }

        &__enedis {
            font-family: Plus Jakarta Sans;
            font-size: 9px;
            font-weight: 500;
            line-height: 12px;
            color: #71717A;
            position: absolute;
            bottom: 15px;

            &bold {
                font-weight: 700;
            }
        }

        &__today {
            bottom: 30px;
        }

        &__activation {
            bottom: 45px;
        }

        &__list {
            display: flex;
            margin-bottom: 5px;
        }

        &__linklist {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            &paragraph {
                display: inline-block;
            }
        }

        &__data {
            font-size: 12px;
            color: #71717A;
            display: block;
            margin-bottom: 5px;

            &container {
                margin: 15px 0;
                padding-bottom: 30px;
            }

            &bold {
                font-weight: 700;
            }
        }
    }

    &--sitename {
        max-width: 250px;
    }

    &--filters {
        &__button {
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 500;
            color: $wu-darkergrey;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        &__container {
            font-family: Plus Jakarta Sans;
            color: $wu-darkergrey;
            width: 270px;
            position: absolute;
            right: 0;
            background-color: white;
            z-index: 2;
            padding: 25px;
            top: 70px;
            border: 1px solid #BFBFBF;
            border-radius: 10px;
            max-height: 450px;
            overflow: auto;
        }

        &__title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        }

        &__subtitle {
            font-size: 14px;
            font-weight: 500;
            margin-top: 20px;
            display: block;
        }

        &__radioinputcontainer {
            margin-top: 7px;
            color: black;

            & .conso--filters__checkmark:after {
                background-color: black;
            }

            & input:checked~.conso--filters__checkmark {
                border: 1px solid black;
            }
        }

        &__valuecontainers {
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            &weeks {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 0 3px;
            }
        }

        &__label {
            color: black;
        }

        &__selectall {
            font-family: Plus Jakarta Sans;
            font-size: 10px;
            font-weight: 500;
            margin-left: 5px;
            color: $wu-darkgrey;
            cursor: pointer;
        }
    }

    &--activatebutton {
        margin-right: 10px;
    }

    &--alert {
        &__dropdown {
            width: 90px;

            &container {
                display: inline-block;
                margin: 0 5px 0 0;
            }
        }
    }

    &--recipients {
        margin: 0 0 10px 0;
        word-break: break-all;

        &__block {
            margin-top: 18px;
        }

        &__container {
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &--tooltip {
        &__container {
            margin: 0px;
            padding: 10px;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(204, 204, 204);
            white-space: nowrap;
            border-radius: 8px;
        }

        &__title {
            color: $wu-darkergrey;
            margin-bottom: 4px;
        }

        &__list {
            &item {
                padding: 4px 0;
            }
        }
    }

    &--choice {
        font-size: 11px;
    }
}

hr {
    border: none;
    border-top: 2px dashed $wu-mediumdarkgrey;
    color: white;
    background-color: white;
    height: 1px;
    position: absolute;
    left: 10px;
    z-index: 0;
    width: 85%
}

.hr {
    &--1 {
        top: 85px;
    }

    &--2 {
        top: 117px
    }

    &--3 {
        top: 149px
    }

    &--4 {
        top: 181px
    }

    &--5 {
        top: 213px
    }

    &--6 {
        top: 245px
    }

    &--7 {
        top: 277px
    }

    &--8 {
        top: 309px
    }

    &--9 {
        top: 341px;
    }

    &--10 {
        top: 373px
    }

    &--11 {
        top: 405px
    }

    &--12 {
        top: 437px
    }

    &--13 {
        top: 469px
    }

    &--14 {
        top: 501px
    }

    &--15 {
        top: 533px
    }

    &--16 {
        top: 565px
    }

    &--17 {
        top: 597px;
    }

    &--18 {
        top: 629px
    }

    &--19 {
        top: 661px
    }

    &--20 {
        top: 693px
    }

    &--21 {
        top: 725px
    }

    &--22 {
        top: 757px
    }

    &--23 {
        top: 789px
    }

    &--24 {
        top: 821px
    }

    &--25 {
        top: 853px;
    }

    &--26 {
        top: 885px
    }

    &--27 {
        top: 917px
    }

    &--28 {
        top: 949px
    }

    &--29 {
        top: 989px
    }

    &--30 {
        top: 1013px
    }

    &--31 {
        top: 1045px
    }

    &--32 {
        top: 1077px
    }

    &--33 {
        top: 1109px;
    }

    &--34 {
        top: 1141px
    }

    &--35 {
        top: 1173px
    }

    &--36 {
        top: 1205px
    }

    &--37 {
        top: 1237px
    }

    &--38 {
        top: 1269px
    }

    &--39 {
        top: 1301px
    }

    &--40 {
        top: 1333px
    }

    &--41 {
        top: 1365px
    }

    &--42 {
        top: 1397px;
    }

    &--43 {
        top: 1429px
    }

    &--44 {
        top: 1461px
    }

    &--45 {
        top: 1493px
    }

    &--46 {
        top: 1525px
    }

    &--47 {
        top: 1557px
    }

    &--48 {
        top: 1589px
    }

    &--49 {
        top: 1621px
    }

    &--50 {
        top: 1653px
    }
}

#table--head__container-0 {
    width: 66px !important;
}

@media screen and (max-width: 1807px) {
    .conso {
        &--button {
            &option {
                &__first {
                    margin-top: 29px;
                }
            }
        }
    }
}

@media screen and (max-width: 1470px) {
    .conso {
        &--containers {
            &__legend {
                flex-direction: column;
            }
        }

        &--legend {
            &__container {
                margin: 15px 0;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .conso {
        &--detail {
            &__today {
                bottom: 45px;
            }

            &__activation {
                bottom: 60px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .conso {
        &--detail {
            &__block {
                width: 100%;
                margin-right: 0;

                &second {
                    margin-top: 20px;
                }

                &third {
                    margin-top: 20px;
                }
            }

            &__container {
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .conso {
        &--detail {
            &__block {
                &second {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .conso {
        &--header {
            &__periodedropdowns {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &--export {
            &__button {
                margin: 15px 0;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .conso {
        &--filters {

            &__container {
                top: 215px;
                left: 0;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .conso {
        &--legend {
            margin-bottom: 4px;

            &__container {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &--filters {

            &__container {
                top: 240px;
                left: 0;
            }
        }
    }
}

@media screen and (max-width: 335px) {
    .conso {
        &--filters {

            &__container {
                top: 260px;
            }
        }
    }
}

@media screen and (min-width: 1441px) {
    .conso {
        &--detail {
            &__container {
                justify-content: flex-start;
            }
        }
    }
}