.dashboard {
    &--header {
        &__container {
            display: flex;
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            line-height: 26px;
            color: $wu-grey;
        }

        &__name {
            font-weight: 700;
            display: block;
            margin-right: 5px;
            color: $wu-darkergrey;
        }

        &__switchcontainer {
            margin-left: 50px;
        }
    }

    &--emptystate {
        font-family: Plus Jakarta Sans;
        line-height: 26px;
        color: $wu-grey;
        font-weight: 400;
    }

    &--button {
        margin-top: 12px;
    }

    &--indicators {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__top {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
        }

        &__left {
            width: 69%;
            margin: 0;
        }

        &__right {
            width: 29%;

            &top {
                margin: 0;
                height: 100%
            }
        }

        &__block {
            background-color: white;
            border-radius: 10px;
            border: 1px solid $wu-lightergrey;
            padding: 20px;
            height: 100%;
            min-height: 119px;

            &large {
                width: 65%;
            }

            &small {
                width: 35%;
            }

            &full {
                width: 100%;
            }

            &container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &left {
                    width: 68%;
                    height: 100%;
                }

                &right {
                    width: 32%;
                    height: 100%;
                }

                &appeloffre {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            &left {
                margin-right: 20px;
            }

            &title {
                font-family: Plus Jakarta Sans;
                font-size: 11px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 1px;
                color: $wu-grey;
                text-transform: uppercase;
                display: flex;
                align-items: center;
            }

            &subtitle {
                font-family: Plus Jakarta Sans;
                font-size: 10px;
                font-style: italic;
                font-weight: 300;
                line-height: 8px;
                color: $wu-grey;
                display: block;
            }

            &number {
                font-family: Plus Jakarta Sans;
                font-size: 21px;
                font-weight: 700;
                line-height: 32px;
                color: $wu-darkergrey;
                display: flex;
                align-items: center;
                margin-top: 4px;
            }

            &margintop6 {
                margin-top: 6px;
            }

            &margintop14 {
                margin-top: 14px;
            }

            &icon {
                margin-right: 5px;
            }

        }

        &__loadercontainer {
            display: flex;
            align-items: flex-end;
        }
    }

    &--number {
        font-family: Plus Jakarta Sans;
        font-size: 13px;
        font-weight: 500;
        line-height: 21px;

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 20px;
        }

        &__up {
            color: $wu-errorred;
        }

        &__down {
            color: $wu-bottlegreen;
        }
    }

    &--addsite {
        font-family: Plus Jakarta Sans;
        font-size: 10px;
        font-weight: 400;
        line-height: 8px;
        color: $wu-grey;
        text-transform: uppercase;
        cursor: pointer;

        &__date {
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 700;
            line-height: 11px;
        }

        &__container {
            margin-top: 8px;
        }
    }

    &--appeloffres {
        &__container {
            display: flex;
            justify-content: space-between;
            margin-top: 18px;
            align-items: center;
        }

        &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__icon {
            margin-right: 12px;
        }

        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 13px;
            font-weight: 700;
            line-height: 16px;
            color: $wu-darkergrey;
        }

        &__subtitle {
            font-family: Plus Jakarta Sans;
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            color: $wu-grey;
            display: block;
        }

        &__result {
            font-family: Plus Jakarta Sans;
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            color: $wu-darkergrey;
            cursor: pointer;
        }
    }

    &--seemore {
        margin-top: 20px;
        cursor: pointer;
        position: absolute;
        bottom: 22px;
    }

    &--conso {
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &container {
                margin-bottom: 15px;
                ;
            }
        }

        &__button {
            border: 1px solid #D4D4D8;
            background-color: white;
            color: $wu-darkergrey;
            width: 215px;
        }
    }

    &--paddingbottom54 {
        padding-bottom: 54px;
    }

    &--graph {
        &__container {
            min-height: 455px;
        }
    }

    &--marginright10 {
        margin-right: 10px;
    }

    &--legend {
        &__container {
            display: flex;
            margin: 10px 0;
            flex-wrap: wrap;
        }
    }

    &--positionrelative {
        position: relative;
    }
}

@media screen and (max-width: 1530px) {
    .dashboard {
        &--conso {

            &__header {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 10px;
            }

            &__button {
                margin-top: 10px;
            }

            &__title {
                margin-bottom: 15px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .dashboard {
        &--appeloffres {
            &__container {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .dashboard {
        &--indicators {
            &__block {
                &left {
                    margin-right: 0;

                    &inside {
                        margin-bottom: 15px;
                    }
                }

                &large {
                    width: 100%;
                }

                &small {
                    width: 100%;
                }

                &container {

                    &up {
                        flex-direction: column;
                        margin-right: 15px;
                    }
                }

            }
        }

        &--addsite {
            &__container {
                flex-direction: column;
                align-items: flex-start;
            }

            &__date {
                margin-bottom: 5px;
            }
        }
    }
}


@media screen and (max-width: 1023px) {
    .dashboard {
        &--addsite {
            &__container {
                flex-direction: row;
                align-items: center;
            }

            &__date {
                margin-bottom: 0px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .dashboard {
        &--indicators {
            &__right {
                width: 34%;
            }

            &__left {
                width: 64%;
            }
        }

        &--conso {
            &__container {
                flex-direction: column;
            }

            &__top {
                width: 100% !important;
            }

            &__bottom {
                width: 100% !important;
                margin-top: 15px;
            }
        }

        &--graph {
            &__container {
                min-height: fit-content;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .dashboard {
        &--header {
            &__container {
                flex-direction: column;
            }

            &__switch {
                margin-left: 0;
            }
        }

        &--indicators {
            &__block {
                &container {
                    flex-direction: column;

                    &left {
                        width: 100%;
                    }

                    &up {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 15px;
                    }

                    &right {
                        width: 100%;
                    }
                }

                &large {
                    margin-bottom: 15px;
                }
            }

            &__right {
                width: 49%;
            }

            &__left {
                width: 49%;
            }
        }

        &--appeloffres {
            &__container {
                flex-direction: row;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .dashboard {
        &--indicators {
            &__left {
                width: 100%;
            }

            &__block {
                &large {
                    width: 100%;
                }
            }

            &__top {
                flex-direction: column;
            }

            &__right {
                width: 100%;


                &top {
                    width: 100%;
                    margin-top: 15px;
                }
            }
        }
    }
}