.sidebar {
    &--container {
        width: 280px;
        position: absolute;
        background-color: white;
        padding: 20px 30px;
        font-family: Plus Jakarta Sans, sans-serif;
        color: $wu-darkgrey;
        left: 0;
        text-align: center;
        height: calc(100% - 120px);
        overflow: auto;
        top: 60px;
        z-index: 99;
    }

    &--addbutton {
        margin: 14px 0 24px;
    }

    &--listelement {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
    }

    &--listicon {
        margin-right: 15px;
        cursor: pointer;
        width: 18px;
        height: 18px;
    }

    &--elementtitle {
        font-size: 13px;
        font-weight: 500;
        line-height: 21px;
        cursor: pointer;

        &__active {
            font-weight: 700;
        }
    }

    &--listtitle {
        font-size: 11px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1px;
        color: $wu-mediumgrey;

        &__container {
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }
    }

    &--newenergybutton {
        width: 220px;
        margin-top: 30px;
        cursor: pointer;
    }

    &--logout {
        font-size: 13px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        position: fixed;
        bottom: 0;
        cursor: pointer;
        background-color: white;
        width: 280px;
        padding: 20px 30px;
        left: 0;
    }

    &--dropdownicon {
        cursor: pointer;
    }
}


@media screen and (max-width: 1023px) {
    .sidebar {
        &--container {
            display: none;

            &mobile {
                display: block;
                padding: 10px 20px;
                width: 245px;
                height: calc(100% - 60px);
                transition: all 0.5s ease;
            }
        }

        &--logo {
            display: none;
        }

        &--baseline {
            display: none;
        }
    }
}