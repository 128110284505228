.elec {
    &--title {
        font-size: 26px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.1em;
        margin-bottom: 6px;
        word-wrap: break-word;
    }

    &--paragraph {
        margin-bottom: 15px;
        line-height: 20px;

        &__withbutton {
            margin: 0 20px 0 0;
        }

        &__container {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }
    }

    &--link {
        color: $wu-bottlegreen;
        text-decoration: underline;
        cursor: pointer;
    }

    &--anomalie {
        color: $wu-errorred;
    }

    &--filter {

        &__searchbar {
            width: 330px;
            height: 35px;
            margin-right: 15px;
            max-width: 82%;
        }

        &__container {
            display: flex;
            margin-bottom: 10px;
        }

        &__dropdown {
            width: 174px;
        }

    }

    &--emptystate {
        margin-top: 20px;
        display: block;
    }

    &--table {
        &__title {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-top: 10px;

            &margintop {
                margin-top: 25px;
            }
        }
    }

    &--separator {
        border-color: black;
    }

    &--center {
        justify-content: center;
    }

    &--upload {
        &__subtitle {
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1em;
            margin: 30px 0 8px;
        }

        &__info {
            font-size: 13px;
            display: block;
        }

        &__button {
            margin-top: 12px;
        }
    }

    &--informationblock {
        display: flex;
        background-color: white;
        border: 1px solid #EFEFEF;
        border-radius: 10px;
        margin-top: 16px;
        padding: 10px 22px;

        &__img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }

        &__title {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.02em;
        }
    }

    &--add {
        &__invoice {
            &title {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.09px;
                letter-spacing: 0.1em;
                color: $wu-bluegrey;
            }

            &subtitle {
                display: block;
                font-size: 10px;
                font-style: italic;
                font-weight: 300;
                line-height: 11.74px;
                letter-spacing: 0.1em;
                color: $wu-bluegrey;
            }
        }

        &__link {
            font-size: 10px;
            font-style: italic;
            font-weight: 700;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &--form {
        &__inputcontainer {
            margin-top: 3px
        }
    }
}

@media screen and (max-width: 768px) {
    .elec {
        &--informationblock {
            flex-direction: column;
            align-items: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .elec {
        &--filter {
            &__container {
                flex-direction: column;
            }

            &__dropdown {
                margin-top: 10px
            }
        }
    }
}