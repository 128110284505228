.sepa {
    &--header {
        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__clientname {
            font-size: 26px;
            font-weight: 700;
            line-height: 16px;
            color: $wu-darkergrey;
            margin: 24px 0;
        }
    }

    &--form {
        &__import {
            font-family: Raleway;
            font-size: 12px;
            font-weight: 600;
            line-height: 14.09px;
            letter-spacing: 0.02em;
            color: $wu-bottlegreen;
            display: block;
            cursor: pointer;
            margin-top: 5px;

            &disabled {
                cursor: not-allowed;
            }
        }

        &__dropdown {
            width: 220px;
            margin-right: 12px;

            &container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 8px;
            }
        }

        &__block {
            width: 370px;
            position: relative;

            &bottom {
                margin-top: 20px;
            }
        }

        &__inputcontainer {
            display: flex;
            margin-top: 10px;
            align-items: center;
        }

        &__uploadcontainer {
            margin-top: 12px;
            position: relative;
        }
    }

    &--middlecolumn {
        margin-top: 32px;
        margin-left: 35px;
        margin-right: 35px;
    }

    &--thirdcolumn {
        margin-top: 32px;
    }

    &--link {
        max-width: 135px;
    }

    &--button {
        &__add {
            color: $wu-bottlegreen;
            background-color: white;
            border: 1px solid $wu-bottlegreen;
            position: relative;
        }
    }

    &--recipients {
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        display: block;
        margin: 10px 0;
        cursor: pointer;
        color: $wu-darkergrey;

        &__container {
            display: flex;
            align-items: center;
        }

        &__icon {
            margin-left: 5px;
        }
    }

    &--emptyrecipients {
        font-size: 13px;
        margin: 5px 0;
        display: block;
    }

    &--emailinput {
        width: calc(100% - 50px);
    }

    &--messageinfo {
        margin: 0;
        text-align: right;
    }

    &--buttonscontainer {
        display: flex;
    }

    &--table {
        &__title {
            padding: 0 10px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.1em;
            display: block;
            margin-bottom: 12px;
        }

        &__searchbarcontainer {
            margin-bottom: 15px;

            &bottom {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .sepa {
        &--header {
            &__container {
                justify-content: center;
                margin-top: 15px;
            }

            &__clientname {
                display: none;
            }
        }

        &--messageinfo {
            text-align: center;
            margin-top: 7px;
        }
    }
}

@media screen and (max-width: 450px) {
    .sepa {
        &--form {
            &__upload {
                width: 230px
            }
        }
    }
}

@media screen and (max-width: 376px) {
    .sepa {
        &--form {
            &__block {
                width: 300px
            }

            &__upload {
                width: 176px
            }
        }
    }
}