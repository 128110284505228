.greenenergy {
    &--title {
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 18px;
        display: block;
    }

    &--paragraph {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.1em;
        text-align: justify;
        margin: 48px 0;

        &__bold {
            font-weight: 700;
        }
    }

    &--showmore {
        font-size: 12px;
        font-weight: 700;
        color: $wu-bottlegreen;
        cursor: pointer;
    }

    &--list {
        &__item {
            font-size: 18px;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 13px;
        }

        &__number {
            font-weight: 700;
            color: $wu-bottlegreen;
        }
    }

    &--moreinfo {
        color: $wu-bottlegreen;
        font-weight: 700;
        cursor: pointer;
    }

    &--block {
        border: 1px solid $wu-lightergrey;
        background-color: white;
        padding: 20px;
        border-radius: 10px;

        &__left {
            width: 70%;
        }

        &__right {
            width: 28%;
            text-align: center;
        }

        &__container {
            display: flex;
            margin: 15px 0;
            justify-content: space-between;
        }

        &__img {
            width: 280px;
            max-width: 100%;

            &subtitle {
                font-size: 18px;
                font-weight: 400;
                margin-top: 15px;
                display: block;
            }
        }
    }

    &--maptitle {
        width: 100%;
        display: block;
        text-align: center;
    }
}

@media screen and (max-width: 750px) {
    .greenenergy {
        &--block {
            &__container {
                flex-direction: column;
            }

            &__left {
                width: 100%;
            }

            &__right {
                width: 100%;
                margin-top: 16px;
            }
        }

    }
}