.homepage {
    &--container {
        background-color: $wu-lightgrey;
        display: flex;
        overflow: auto;
        position: fixed;
    }

    &--publiccontainer {
        height: 100%;
        width: 100%;
    }

    &--url {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.44px;
        letter-spacing: 0.1em;
        color: $wu-magentablue;
        text-decoration: underline;
        display: block;
        cursor: pointer;

        &__password {
            text-align: right;
            margin-bottom: 18px;
            margin-top: 4px;
        }

        &__createaccount {
            text-align: center;
            margin-top: 6px;
        }
    }

    &--popup {
        position: relative;
        width: 57%;
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        display: flex;
        margin: auto;
        background-color: white;
        max-width: 1000px;

        &__logo {
            text-align: center;

            &img {
                max-width: 100%;
            }

            &passwordfail {
                width: 100%;
            }
        }

        &__baseline {
            display: block;
            font-size: 10px;
            font-weight: 600;
            line-height: 16px;
            text-align: center;
            color: $wu-slategrey;
            margin-top: 2px;
        }

        &__leftpart {
            width: 50%;
            padding: 40px 50px;
        }

        &__img {
            width: 50%;
            border-radius: 0 24px 24px 0;
            object-fit: cover;
            box-shadow: 8px 8px 50px 0px #00000040;
        }

        &__formtitle {
            font-size: 22px;
            font-weight: 700;
            line-height: 25.83px;
            letter-spacing: 0.04em;
            color: $wu-bluegrey;
            display: block;
            margin: 10px 0 5px;

            &passwordfail {
                margin: 15px 0;
            }
        }

        &__formsubtitle {
            font-size: 12px;
            font-weight: 500;
            line-height: 14.09px;
            letter-spacing: 0.1em;
            color: $wu-bluegrey;
            margin-bottom: 15px;
            display: block;
        }

        &__formcontainer {
            display: flex;
            flex-direction: column;
        }

        &__inputlabel {
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.09px;
            letter-spacing: 0.1em;
            color: $wu-bluegrey;
            margin: 12px 0 4px 0;

            &margintop0 {
                margin-top: 0;
            }
        }

        &__input {
            border: 1px solid $wu-lightergrey;
            border-radius: 6px;
            height: 15px;
            padding: 10px;
            width: calc(100% - 25px);

            &marginbottom33 {
                margin-bottom: 33px;
            }

            &marginbottom14 {
                margin-bottom: 14px;
            }

            &marginbottom8 {
                margin-bottom: 8px;
            }

            &::placeholder {
                color: $wu-lightergrey;
            }

            &placeholder {
                color: $wu-mediumgrey;
            }

            &:focus {
                outline: 1px solid black;
            }
        }

        &__dropdown {
            height: 36.6px;
            width: 158px;
        }

        &__icon {
            position: absolute;
            right: 12px;
            top: 42px;
            cursor: pointer;

            &crossedout {
                top: 40px;
            }
        }

        &__passwordinputcontainer {
            width: 100%;
            position: relative;
        }

        &__noaccount {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            text-align: center;
            color: $wu-slategrey;
            display: block;
            margin-top: 20px;
        }

        &__newsletter {
            display: block;
            position: absolute;
            bottom: -35px;
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.78px;
            letter-spacing: 0.1em;
            cursor: pointer;
        }

        &__message {
            display: block;
            font-size: 12px;
            margin: 7px 0;

            &center {
                text-align: center;
            }

            &error {
                color: $wu-errorred;
            }

            &confirmation {
                color: $wu-bottlegreen;
            }
        }

        &__multipleinputcontainer {
            display: flex;

            &marginbottom {
                margin-bottom: 7px;
            }

            &margintop {
                margin-top: 7px;
            }
        }

        &__insideinputcontainer {
            width: calc(50% - 5px);

            &left {
                margin-right: 10px;
            }
        }

        &__radioinputcontainer {
            display: block;
            position: relative;
            padding-left: 20px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.09px;
            letter-spacing: 0.1em;
            color: $wu-bluegrey;
            margin-right: 10px;

            & input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            &:hover input~.homepage--popup__checkmark {
                background-color: white;
            }

            & input:checked~.homepage--popup__checkmark:after {
                display: block;
            }

            & .homepage--popup__checkmark:after {
                top: 3px;
                left: 3px;
                width: 6px;
                height: 6px;
                border-radius: 50px;
                background-color: $wu-bluegrey;
            }

            & input:checked~.homepage--popup__checkmark {
                background-color: white;
                border: 1px solid $wu-bluegrey;
            }
        }

        &__checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 14px;
            width: 14px;
            background-color: white;
            border-radius: 50px;
            border: 1px solid $wu-lightergrey;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }

        &__passwordfail {
            padding: 40px 50px;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        &__mandatory {
            font-size: 10px;
            color: $wu-bluegrey;
            margin-top: 7px;
        }

        &__textarea {
            display: block;
            width: calc(100% - 22px);
            border: 1px solid $wu-lightergrey;
            border-radius: 6px;
            height: 100px;
            margin-bottom: 12px;
            resize: none;
            padding: 10px;

            &::placeholder {
                color: $wu-mediumgrey;
            }
        }
    }

    &--fileinput {
        border: 1px solid $wu-lightergrey;
        display: inline-block;
        padding: 10px 12px 6px;
        cursor: pointer;
        border-radius: 6px;
        width: 307px;
        color: $wu-mediumgrey;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 16.44px;
        letter-spacing: 0.1em;

        &__selected {
            color: $wu-darkergrey;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: $wu-lightergrey;
            cursor: not-allowed;
        }

        &__container {
            position: relative;
        }

        &__upload {
            position: absolute;
            top: 8px;
            right: 12px;
        }

        &__showdocument {
            cursor: pointer;
            top: 11px;
        }
    }

    &--checkbox {
        margin: 0;

        &__container {
            display: flex;
            margin-top: 5px;
        }

        &__label {
            margin: 0 0 0 5px;
        }
    }

    &--button {
        &__confirmation {
            margin: 0 auto 20px;
            width: fit-content;
        }
    }

    &--color__black {
        color: black;
    }
}

input[type="checkbox"] {
    accent-color: $wu-bluegrey;
}


input[type="file"] {
    display: none;
}

@media screen and (max-width: 1023px) {
    .homepage {
        &--popup {
            width: 80%;

            &__leftpart {
                width: 100%;
                padding: 25px 20px;
            }

            &__img {
                display: none;
            }

            &__logoimg {
                width: 150px;
            }

            &__newsletter {
                bottom: -55px;
            }
        }
    }
}

@media screen and (max-width: 370px) {
    .homepage {
        &--popup {
            &__formtitle {
                font-size: 19px;
            }

            &__inputlabel {
                font-size: 10px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .homepage {
        &--fileinput {
            width: 220px;

            &__upload {
                display: none;
            }
        }
    }
}