@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

body {
  margin: 0;
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.global {
  &--button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    background-color: $wu-bottlegreen;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;

    &__plus {
      font-size: 18px;
      margin-right: 10px;
    }

    &__margintop8 {
      margin-top: 8px;
    }

    &__margintop33 {
      margin-top: 33px;
    }

    &__disabled {
      background-color: $wu-lightergrey;
      color: $wu-slategrey;
      cursor: not-allowed;
    }

    &__goback {
      color: $wu-mediumgrey;
      background-color: white;
      border: 1px solid $wu-mediumgrey;
      margin-right: 10px;
    }

    &__secondary {
      background-color: white;
      border: 1px solid $wu-bottlegreen;
      margin-right: 10px;
      color: $wu-bottlegreen;
    }

    &__black {
      background-color: white;
      border: 1px solid black;
      margin-right: 10px;
      color: black;
    }
  }

  &--loader {
    width: 35px;
    height: 35px;
    border: 5px solid $wu-bottlegreen;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: block;
    margin: auto;
    box-sizing: border-box;
    animation: rotation 1.2s linear infinite;
  }

  &--loadersmall {
    width: 10px;
    aspect-ratio: 3;
    background: radial-gradient(circle closest-side, $wu-grey 90%, #0000) 0/calc(100%/3) 100% no-repeat;
    animation: l2 1s steps(3) infinite;
    margin-left: 5px;
  }

  &--color__green {
    color: $wu-bottlegreen;
  }

}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes l2 {
  to {
    background-position: 150%
  }
}