.table {
    &--link {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-decoration: underline;
        color: $wu-bottlegreen;
        cursor: pointer;
    }

    &--icon {
        margin-left: 5px;
        cursor: pointer;

        &__container {
            display: flex;
            justify-content: center;
        }
    }

    &--tablecontainer {
        width: 100%;
        background-color: white;
        border: 1px solid $wu-lightergrey;
        border-radius: 10px;
        margin-top: 16px;
        position: relative;
        min-height: 300px;
        padding: 22px;
        font-weight: 500;

        &__button {
            width: fit-content;
        }
    }

    &--searchbarcontainer {
        display: flex;
        margin-bottom: 33px;
        justify-content: space-between;
        position: relative;

        &__siteslist {
            margin: 16px 0;
        }
    }

    &--addbutton {
        position: absolute;
        right: 22px;
        top: 22px;
    }

    &--checkicon {
        margin-left: 12px;
    }

    &--sendicon {
        cursor: pointer;
    }

    &--sent {
        padding: 4px 0;
    }

    &--head {
        &__container {
            display: flex;
            position: relative;
        }
    }

    &--alignright {
        text-align: right;
    }

    &--flexend {
        justify-content: flex-end;
    }

    &--cell {
        &__limited {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: break-spaces;
            overflow: hidden;
            text-overflow: ellipsis;
       
        }
    }
}

@media screen and (max-width: 768px) {
    .table {
        &--searchbarcontainer {
            flex-direction: column-reverse;
        }

        &--form {
            width: calc(100% - 40px) !important;
            margin-top: 12px;
        }

        &--addbutton {
            position: initial;
            margin-bottom: 15px;
        }
    }
}

@media screen and (max-width: 1050px) {
    .table {
        &--addbutton {
            position: initial;
            margin-bottom: 15px;
        }
    }
}