.addsites {
    &--choosetype {
        &__container {
            margin: 15px 0 10px;
        }

        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 10px;
            display: block;
        }

        &__switchcontainer {
            display: flex;
            align-items: center;
        }

        &__energy {
            font-family: Plus Jakarta Sans;
            font-size: 13px;
            font-weight: 400;

            &active {
                font-weight: 700;
                color: #48AB4D;
            }
        }
    }

    &--step {
        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 26px;
            font-weight: 700;
            color: $wu-darkergrey;
            margin: 30px 0 20px;

            &container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &right {
                font-weight: 400;
            }
        }

        &__button {
            height: fit-content;
        }
    }

    &--dropdown {
        &__container {
            display: flex;
            align-items: flex-end;
        }

        &__elec {
            &container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 8px;
            }

            &dropdown {
                width: 68%
            }
        }
    }

    &--importbutton {
        height: fit-content;
        margin-left: 12px;
        margin-right: 0;
    }

    &--enddate {
        &__label {
            font-size: 12px;
            line-height: 14.09px;
            letter-spacing: 0.1em;
            color: $wu-bluegrey;
            display: block;
            margin: 12px 0 4px;
            font-weight: 500;
        }

        &__disabled {
            background-color: #EFEFEF;
            font-size: 14px;
            font-style: italic;
            line-height: 14px;
            letter-spacing: 0.1em;
            color: $wu-bluegrey;
            display: block;
            border-radius: 6px;
            padding: 10px 15px;
            width: fit-content;
        }

        &__cliquable {
            cursor: pointer;
        }

        &__subtitle {
            font-size: 9px;
            font-style: italic;
            font-weight: 300;
            letter-spacing: 0.1em;
            display: block;
            color: $wu-bluegrey;
            margin-bottom: 6px;
        }
    }

    &--calendar {
        &__container {
            position: absolute;
            background-color: white;
            padding: 10px;
            border: 1px solid $wu-lightergrey;
            border-radius: 6px;
            z-index: 99;
            top: 0;
            right: -310px;
            text-align: right;
        }
    }

    &--link {
        font-family: Plus Jakarta Sans;
        font-size: 11px;
        font-weight: 700;
        line-height: 11px;
        color: $wu-bottlegreen;
        text-decoration: underline;
        margin: 4px 0;
        display: block;
        cursor: pointer;
    }

    &--notabene {
        font-family: Plus Jakarta Sans;
        font-size: 11px;
        font-style: italic;
        font-weight: 300;
        line-height: 11px;
        display: block;
        margin-bottom: 12px;
    }

    &--bottomtext {
        margin-top: 5px;
        width: 380px;
    }

    &--intro {
        display: block;
        text-align: center;
    }

    &--radiogroup {
        &__trv {
            margin-left: 12px;
        }
    }

    &--prices {
        &__container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 20px 0;
        }

        &__block {
            width: 28%;
            padding: 25px;
            border: 1px solid $wu-lightergrey;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 230px;
            max-width: 350px;
            margin-top: 20px;
        }

        &__text {
            font-size: 14px;
            letter-spacing: 0.1em;
            text-align: center;
        }

        &__price {
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 8px;
        }
    }

    &--date {
        min-width: 117px;
        min-height: 38px;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 1305px) {
    .addsites {

        &--prices {
            &__container {
                justify-content: center;
            }

            &__block {
                margin-right: 10px;
            }
        }
    }
}

@media screen and (max-width: 1439px) {
    .addsites {

        &--calendar {
            &__container {
                right: 34px;
                top: 335px;

            }
        }
    }
}

@media screen and (max-width: 700px) {
    .addsites {

        &--step {
            &__title {
                &container {
                    flex-direction: column;
                }
            }
        }

        &--bottomtext {
            width: 280px;
        }
    }
}

@media screen and (max-width: 376px) {
    .addsites {

        &--calendar {
            &__container {
                right: 0
            }
        }

        &--prices {
            &__block {
                margin-right: 0;
            }
        }
    }
}