.budget {
    &--more {
        left: 40px;
        bottom: initial;
        font-size: 16px;
        margin-top: 5px;
    }

    &--indicators {
        &__top {
            margin-top: 30px;
        }

        &__blocknumbers {
            justify-content: space-between;
        }

        &__right {
            &top {
                min-height: fit-content;
                height: 96px;
                margin: 0;
            }

            &bottom {
                height: calc(100% - 112px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            color: $wu-darkergrey;
            display: block;
        }
    }

    &--graph {
        &__container {
            margin: 20px 0;

            &byyear {
                display: flex;
                margin-bottom: 7px;

                &camembert {
                    flex-direction: column;
                }
            }
        }
    }

    &--nodata {
        display: block;
        margin-top: 15px;
    }

    &--table {
        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: $wu-darkergrey;
            display: block;
            margin-bottom: 10px;
        }

        &__data {
            font-family: Plus Jakarta Sans;
            font-size: 10px;
            font-style: italic;
            font-weight: 400;
            display: block;
            color: $wu-darkergrey;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;
        }
    }

    &--checkall {
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        color: $wu-darkergrey;
    }

    &--site {
        &sarray {
            &__container {
                position: relative;
            }
        }

        &__container {
            margin-top: 25px;
        }

        &__header {
            margin-left: 10px;
            margin-top: 10px;

            &checkbox {
                display: flex;
                align-items: center;
            }
        }
    }

    &--line {
        &__vertical {
            &:after {
                content: "";
                position: absolute;
                z-index: 99;
                top: 27px;
                bottom: 0;
                left: 17px;
                border-left: 1px dashed #3F3F46;
            }
        }
    }

    &--legend {

        &__camembert {
            align-items: center;
            margin-bottom: 5px;
        }

    }

    &--dropdown {
        margin-right: 15px;
    }
}

@media screen and (max-width: 1120px) {
    .budget {
        &--graph {

            &__container {
                &byyear {
                    flex-direction: column;
                }
            }
        }

        &--legend {
            margin-bottom: 5px;
        }
    }
}

@media screen and (max-width: 550px) {
    .budget {
        &--indicators {

            &__right {
                &top {
                    margin-top: 16px;
                }
            }
        }

        &--filtres {
            &__container {
                top: 80px;
            }
        }

        &--table {
            &__header {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}

@media screen and (max-width: 335px) {
    .budget {

        &--filtres {
            &__container {
                top: 90px;
            }
        }

        &--searchbar {
            width: auto;
        }
    }
}