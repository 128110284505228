.mandates {
    &--container {
        padding-bottom: 20px
    }

    &--header {
        display: flex;
        cursor: pointer;

        &__arrow {
            margin-bottom: 12px;
        }

        &__searchbar {
            margin-bottom: 12px;
        }
    }

    &--site {
        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 15px;
            font-weight: 700;
            color: $wu-darkergrey;
            margin-bottom: 10px;
        }
    }

    &--link {
        display: block;
        max-width: 95px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 7px;

        &__container {
            display: flex;
        }

        &nomargin {
            margin: 0
        }
    }

    &--file {
        cursor: pointer;
        display: flex;
    }

    &--block {
        &__container {
            min-height: fit-content;
        }
    }
}