.appeloffre {
    &--header {
        &__container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            align-items: center;

            &left {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        &__link {
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            color: $wu-darkergrey;
            cursor: pointer;
            margin-left: 20px;
            text-align: right;
        }

        &__icon {
            width: 12px;
            height: 12px;
            margin-left: 4px;
        }

        &__title {
            margin: 0;
        }

        &__linkscontainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        &__pastille {
            padding: 3px 8px;
            border-radius: 35px;
            margin-left: 15px;
            font-family: Plus Jakarta Sans;
            font-size: 10px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 1px;
            cursor: default;
            text-wrap: nowrap;

            &inscriptionencours {
                background-color: $wu-bottlegreen;
                color: white;
                width: 155px;
            }

            &tourindicatifencours {
                background-color: white;
                color: $wu-bottlegreen;
                border: 1px solid $wu-bottlegreen;
                width: 180px;
            }

            &resultats {
                color: black;
                background-color: #D9D9D9;
                width: 220px;

                &indicatifs {
                    width: 230px;
                    background-color: white;
                    border: 1px solid $wu-errorred;
                    color: $wu-errorred;
                }
            }

            &reengagement {
                border: 1px solid $wu-bottlegreen;
                color: $wu-bottlegreen;
                width: 182px;
                display: flex;
                align-items: center;
                position: relative;
            }
        }

        &__dropdownicon {
            margin-left: 5px
        }

        &__spacebetween {
            justify-content: space-between;
        }

        &__width240 {
            min-width: 240px;
        }
    }

    &--modal {
        &__container {
            padding: 30px;
            width: 426px;
            left: calc(50% - 213px);
            top: calc(50% - 191px)
        }

        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 20px;
            font-weight: 700;
            line-height: 14px;
            color: $wu-darkergrey;
            margin-bottom: 15px;
        }

        &__text {
            font-size: 11px;
            font-weight: 500;
            line-height: 12px;
            color: $wu-bluegrey;
            margin-bottom: 5px;
        }

        &__date {
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.1em;
        }
    }

    &--title {
        font-size: 18px;
        margin-top: 30px;
    }

    &--result {
        &__left {
            width: calc(100% - 320px);
            min-height: 170px;
        }

        &__right {
            width: 300px;
            margin-left: 20px;
            background-color: $wu-bottlegreen;
            border-radius: 10px;
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 13px;
            font-weight: 400;
            flex-direction: column;
            font-family: Plus Jakarta Sans;
        }

        &__car {
            font-size: 42px;
            font-weight: 700;
            line-height: 60px;
        }

        &__top {
            display: flex;
        }

        &__type {
            font-size: 16px;
            font-weight: 400;
        }

        &__value {
            font-size: 16px;
            font-weight: 700;
            margin-right: 10px;
        }

        &__valuecontainer {
            margin-bottom: 10px;
        }

        &__texte {
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            text-align: justify;
            margin-bottom: 8px;
            white-space: pre-wrap;
        }

        &__fournisseur {
            display: block;
            color: $wu-darkergrey;
            font-family: Plus Jakarta Sans;
            font-size: 18px;
            font-weight: 700;
            line-height: 14px;
            margin-bottom: 10px;
        }

        &__date {
            display: block;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 20px;
        }

        &__list {
            list-style-type: disc;
            margin-left: 25px;

            &second {
                list-style-type: circle;
                margin-left: 45px;
            }

            &third {
                list-style-type: square;
                margin-left: 65px;
            }
        }

        &__price {
            font-weight: 700;
        }

        &__indicatif {
            font-family: Plus Jakarta Sans;
            font-size: 10px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 1px;
            padding: 3px 8px;
            border: 1px solid $wu-errorred;
            border-radius: 35px;
            color: $wu-errorred;
            text-transform: uppercase;
        }
    }

    &--reengagement {
        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 15px;
            font-weight: 700;
            line-height: 15px;
            color: $wu-bottlegreen;
            margin: 8px 0;
            display: block;
        }

        &__icon {
            margin-right: 5px;
        }
    }

    &--indicatif {
        &__container {
            display: flex;
            justify-content: space-between;

            &gaz {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 16px;
            }
        }

        &__block {
            width: 49%
        }

        &__subtitle {
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            color: $wu-darkergrey;
            display: block;
        }

        &__title {
            font-family: Plus Jakarta Sans;
            font-size: 18px;
            font-weight: 700;
            color: $wu-darkergrey;
            display: block;
            margin: 10px 0;
        }

        &__asterisque {
            margin-bottom: 0;
        }

        &__report {
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-style: italic;
            font-weight: 300;
            display: block;
            margin-bottom: 15px;

            &button {
                background-color: $wu-mediumdarkgrey;
                color: black;
            }
        }

        &__checktext {
            font-size: 12px;
            font-weight: 400;
            padding-left: 5px;
        }

        &__checkcontainer {
            display: flex;
            align-items: flex-start;
            margin-top: 20px;
        }

        &__checkbutton {
            border: 1px solid black;
            background-color: white;
            color: black;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1530px) {
    .appeloffre {
        &--header {
            &__container {
                &left {
                    flex-direction: column;
                    align-items: flex-start;
                    min-width: 245px;
                }
            }

            &__pastille {
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .appeloffre {
        &--header {
            &__container {
                flex-direction: column;
                align-items: flex-start;
            }

            &__linkscontainer {
                margin-top: 10px;
            }

            &__link {
                &first {
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 745px) {
    .appeloffre {
        &--result {
            &__top {
                flex-direction: column-reverse;

            }

            &__left {
                width: 100%
            }

            &__right {
                width: 100%;
                margin: 16px 0 0 0;
                height: 125px;
            }
        }

        &--indicatif {
            &__container {
                flex-direction: column;

                &gaz {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__block {
                width: 100%
            }
        }
    }
}


@media screen and (max-width: 520px) {
    .appeloffre {
        &--modal {
            &__container {
                width: 365px;
                left: calc(50% - 181px);
                top: calc(50% - 100px);
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .appeloffre {
        &--modal {
            &__container {
                width: 300px;
                left: calc(50% - 150px);
                top: calc(50% - 120px);
            }
        }

        &--result {
            &__car {
                font-size: 30px;
            }
        }
    }
}