.privatepages {
    &--container {
        top: 60px;
        left: 280px;
        width: calc(100% - 280px);
        height: calc(100% - 60px);
        padding: 30px 40px 60px;
        display: block;
    }

    &--text {
        font-family: Plus Jakarta Sans, sans-serif;
        color: $wu-darkergrey;
    }

    &--title {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 5px;
    }

    &--separator {
        border: 1px solid $wu-lightergrey;
        margin-top: 8px;
    }

    &--asterisk {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 12px;
        display: block;
        margin-top: 20px;
    }

    &--columnscontainer {
        display: flex;
    }

    &--twocolumns {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__left {
            margin-right: 50px;
        }

        &__right {
            margin-top: 31px;
        }
    }

    &--form {
        &__block {
            border: 1px solid $wu-lightergrey;
            border-radius: 10px;
            background-color: white;
            margin-top: 6px;
            padding: 15px 20px;
            width: 380px;

            &siteslist {
                min-width: 745px;
            }
        }

        &__title {
            font-size: 16px;
            font-weight: 700;
        }

        &__subtitle {
            font-family: Plus Jakarta Sans;
            font-size: 9px;
            font-style: italic;
            font-weight: 300;
            line-height: 9px;
        }
    }

    &--eyeicon {
        top: 30px;

        &__crossedout {
            top: 28px;
        }
    }

    &--button {
        margin-top: 12px;
    }

    &--header {
        &__container {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }

        &__return {
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.1em;
            color: $wu-bottlegreen;
            font-size: 14px;
            font-family: Plus Jakarta Sans, sans-serif;
        }
    }
}

@media screen and (max-width: 1520px) {
    .privatepages {

        &--columnscontainer {
            &__contributors {
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 1439px) {
    .privatepages {

        &--columnscontainer {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 1200px) {
    .privatepages {

        &--columnscontainer {
            align-items: center;
        }

        &--twocolumns__left {
            margin: 0;
        }

        &--twocolumns__right {
            margin-bottom: 60px;
            width: auto;
        }
    }
}

@media screen and (max-width: 1023px) {
    .privatepages {
        &--form {
            &__block {

                &siteslist {
                    min-width: 100%;
                    width: 700px;
                }
            }
        }

        &--container {
            left: 0;
            width: 100%;
            height: calc(100% - 60px);
        }
    }
}

@media screen and (max-width: 768px) {
    .privatepages {

        &--header {
            &__container {
                flex-direction: column;
            }

            &__return {
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .privatepages {
        &--form {
            &__block {
                width: 400px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .privatepages {

        &--form {
            &__block {
                width: 300px;
            }
        }
    }
}

@media screen and (max-width: 377px) {
    .privatepages {

        &--header {
            &__return {
                font-size: 11px;
            }
        }
    }
}